import { makeAutoObservable, reaction, toJS, runInAction } from "mobx";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { merge } from "lodash";
import { parseFullName } from "parse-full-name";
import { client } from "api/client";
import projectsStore from "./projectsStore";
import { Userpilot } from "userpilot";
import flagsmith from "flagsmith";
import {
  GET_USER,
  GET_USER__TYPE,
  GET_PROJECT_INVITES_FOR_USER,
} from "api/query";
import {
  CREATE_SUPPORT_TICKET,
  CREATE_SUPPORT_TICKET__TYPE,
  CREATE_USER_PERSONA,
  UPDATE_USER_PERSONA,
  RESEND_VERIFY_BILLING_EMAIL,
  JOIN_PROJECT,
  DECLINE_PROJECT_INVITE,
  LEAVE_PROJECT,
  VERIFY_EMAIL,
  VERIFY_BILLING_EMAIL,
} from "api/mutations";
import {
  getFromLocalStorage,
  storeDirectlyIntoLocalStorage,
  removeDirectlyFromLocalStorage,
  LOCAL_STORAGE_USER,
  LOCAL_STORAGE_FORM,
  USER_ID,
  USER,
  EMAIL,
  PHONE,
  CREATED,
  IDP,
  REGISTRATION_FORM,
  UI_STATE,
  LATEST_CAMPAIGN,
  URL_SEARCH_PARAMS,
  getFromStorage,
  MULTI_FACTOR_AUTH,
  SERVICE_OVERVIEW_SETTINGS,
  HIDE_UPGRADE_CARD,
  COMPRESSION_USED,
  STORAGE_TYPE,
  ORIGIN,
  NEW_USER_DATA,
} from "utils/localStorage";
import {
  FetchResult,
  ObservableQuery,
  OperationVariables,
} from "@apollo/client";
import {
  CreateUserPersonaMutationVariables,
  DeclineProjectInviteMutationVariables,
  JoinProjectMutationVariables,
  LeaveProjectMutationVariables,
  Project,
  ProjectInvite,
  ResendVerifyBillingEmailAddressMutationVariables,
  Service,
  SupportTicketInput,
  UpdateUserPersonaMutationVariables,
  User,
  VerifyBillingEmailAddressMutation,
  VerifyBillingEmailAddressMutationVariables,
  VerifyEmailMutationVariables,
} from "graphql/generated";
import {
  HubspotField,
  HubspotUserProps,
  HubspotFormProps,
  Subscription,
  UTM_CAMPAIGNS,
} from "types";
import { ENV } from "utils/hostEnv";
import { ACTION, PROD, FORM_SUBSCRIPTION_IDS } from "utils/config";
import notificationStore from "./notificationStore";
import featuresStore from "./featuresStore";
import authStore from "./authStore";
import serviceStore from "./serviceStore";
import { READY, NOT_INITIATED } from "stores/constants";
import Logger from "utils/logger";
import { getHubSpotUtk, getGaClientId } from "utils/utilFunctions";
import { ZAPIER_ONBOARDING_ENDPOINT } from "utils/zapier";

const localUserId = getFromLocalStorage(USER_ID, LOCAL_STORAGE_USER);
const localUser = getFromLocalStorage(USER, LOCAL_STORAGE_USER);
const localEmail = getFromLocalStorage(EMAIL, LOCAL_STORAGE_USER);
const localCreated = getFromLocalStorage(CREATED, LOCAL_STORAGE_USER);
const localPhone = getFromLocalStorage(PHONE, LOCAL_STORAGE_USER) || "";
const localOrigin = getFromLocalStorage(ORIGIN, LOCAL_STORAGE_USER);
const localRegistrationForm = getFromLocalStorage(
  REGISTRATION_FORM,
  LOCAL_STORAGE_FORM,
);
const localUiState = getFromLocalStorage(UI_STATE, LOCAL_STORAGE_USER);

const localMfaMethods = getFromLocalStorage(
  MULTI_FACTOR_AUTH,
  LOCAL_STORAGE_USER,
);

const getLatestCampaign = (): {} | Record<string, string> => {
  const latestCampaignFromStorage = getFromStorage(
    LATEST_CAMPAIGN,
    URL_SEARCH_PARAMS,
    null,
    STORAGE_TYPE.LOCAL,
  );
  return latestCampaignFromStorage
    ? {
        latest_campaign: Array.isArray(latestCampaignFromStorage)
          ? latestCampaignFromStorage.join(",")
          : latestCampaignFromStorage,
      }
    : {};
};

export class UserStore {
  userOrigin = localOrigin;
  currentId = localUserId;
  currentUser = localUser;
  currentPhone = localPhone;
  currentEmail = localEmail;
  currentCreated = localCreated;
  pendingInvitations = [];
  currentIDP: boolean | null | undefined = undefined;
  registrationForm = localRegistrationForm;
  uiState = localUiState || {};
  storeStatus = NOT_INITIATED;
  mfaMethods = localMfaMethods || [];
  userInvitationsSubscription:
    | ObservableQuery<any, OperationVariables>
    | undefined = undefined;
  refreshUserInvitations: Subscription | undefined = undefined;
  verifyBillingEmailAddressPromise = null as unknown as Promise<
    FetchResult<VerifyBillingEmailAddressMutation>
  >;
  getStartedProgress = 0;

  constructor() {
    makeAutoObservable(this);
    this.getUser();

    runInAction(() => {
      featuresStore.currentUserId = this.currentId;
    });
    reaction(
      () => this.currentId,
      (id) => {
        storeDirectlyIntoLocalStorage(USER_ID, toJS(id), LOCAL_STORAGE_USER);
        runInAction(() => {
          featuresStore.currentUserId = id;
        });
      },
    );
    reaction(
      () => this.currentUser,
      (user) =>
        storeDirectlyIntoLocalStorage(USER, toJS(user), LOCAL_STORAGE_USER),
    );
    reaction(
      () => this.currentEmail,
      (email) =>
        storeDirectlyIntoLocalStorage(EMAIL, toJS(email), LOCAL_STORAGE_USER),
    );
    reaction(
      () => this.currentIDP,
      (idp) =>
        storeDirectlyIntoLocalStorage(IDP, toJS(idp), LOCAL_STORAGE_USER),
    );
    reaction(
      () => this.userOrigin,
      (origin) =>
        storeDirectlyIntoLocalStorage(ORIGIN, toJS(origin), LOCAL_STORAGE_USER),
    );
    reaction(
      () => this.currentPhone,
      (phone) =>
        storeDirectlyIntoLocalStorage(PHONE, toJS(phone), LOCAL_STORAGE_USER),
    );
    reaction(
      () => this.currentCreated,
      (created) =>
        storeDirectlyIntoLocalStorage(
          CREATED,
          toJS(created),
          LOCAL_STORAGE_USER,
        ),
    );
    reaction(
      () => this.uiState,
      (uiState) => {
        storeDirectlyIntoLocalStorage(
          UI_STATE,
          toJS(uiState),
          LOCAL_STORAGE_USER,
        );
      },
    );
    reaction(
      () => this.uiState.flagsmithId,
      async (flagsmithId) => {
        if (flagsmithId) {
          await this._setFlagsmithAndHeapIdentities(this.uiState.flagsmithId, {
            email: this.currentEmail,
            ...getLatestCampaign(),
          });
        }
      },
    );
    reaction(
      () => this.registrationForm,
      (registrationForm) => {
        if (registrationForm !== null) {
          registrationForm
            ? storeDirectlyIntoLocalStorage(
                REGISTRATION_FORM,
                toJS(registrationForm),
                LOCAL_STORAGE_FORM,
              )
            : removeDirectlyFromLocalStorage(
                REGISTRATION_FORM,
                LOCAL_STORAGE_FORM,
              );
        }
      },
    );
    reaction(
      () => this.mfaMethods,
      (methods) => {
        storeDirectlyIntoLocalStorage(
          MULTI_FACTOR_AUTH,
          toJS(methods),
          LOCAL_STORAGE_USER,
        );
      },
    );
  }

  get hasMfa() {
    return this.mfaMethods.length > 0;
  }

  updateName = (name: User["name"]) => {
    this.currentUser = name;
  };
  updateId = (id: User["id"]) => {
    this.currentId = id;
  };
  updateEmail = (email: User["email"]) => {
    this.currentEmail = email;
  };
  updatePhone = (phone: User["phone"]) => {
    this.currentPhone = phone;
  };
  updateCreated = (created: User["created"]) => {
    this.currentCreated = created;
  };

  updateIDP = (idp: User["managedByIdp"]) => {
    this.currentIDP = idp;
  };

  updateOrigin = (origin: User["origin"]) => {
    this.userOrigin = origin;
  };

  _updateStoreUiState = ({ uiState }: { uiState: { [key: string]: any } }) => {
    runInAction(() => {
      this.uiState = uiState || {};
    });
  };

  setMfaMethods = (mfaMethods: any[] = []) => {
    this.mfaMethods = mfaMethods;
  };

  resetMfaMethods = () => {
    this.mfaMethods = [];
  };

  updateGetStartedProgress = (progress: number) => {
    this.getStartedProgress = progress;
  };

  setUser = ({
    name,
    email,
    phone,
    created,
    mfaMethods,
    managedByIdp,
    origin,
  }: Pick<
    User,
    | "name"
    | "email"
    | "phone"
    | "created"
    | "mfaMethods"
    | "managedByIdp"
    | "origin"
  >) => {
    if (name && name !== this.currentUser) this.updateName(name);
    if (email && email !== this.currentEmail) this.updateEmail(email);
    if (phone && phone !== this.currentPhone) this.updatePhone(phone);
    if (created && created !== this.currentCreated) this.updateCreated(created);
    if (managedByIdp && managedByIdp !== this.currentIDP)
      this.updateIDP(managedByIdp);
    if (Array.isArray(mfaMethods) && mfaMethods.length > 0) {
      this.setMfaMethods(mfaMethods);
    }
    if (origin && origin !== this.userOrigin) this.updateOrigin(origin);

    this.setThirdPartyIdentify({ id: this.currentId, name, email, created });
  };

  setThirdPartyIdentify = async ({
    id,
    name,
    email,
    created,
  }: Pick<User, "id" | "name" | "email" | "created">) => {
    const latestCampaign = getLatestCampaign();

    await this.sendHubspotEmbeddedForm({ id, email, name });
    await Userpilot.identify(id, {
      name,
      email,
      created_at: created,
      ...latestCampaign,
    });
    if (ENV.NAME === PROD) {
      // Setup clarity identify
      if (window.clarity) {
        window.clarity("set", "userID", id);
        const projectId = projectsStore?.currentProject?.id ?? null;
        if (projectId) {
          window.clarity("set", "projectID", projectId);
        }
      }
    }

    // setup flagsmith and heap identify (uses flagsmithId to identify)
    if (this.uiState.flagsmithId) {
      await this._setFlagsmithAndHeapIdentities(this.uiState.flagsmithId, {
        email,
        ...latestCampaign,
      });
    }
  };

  _setFlagsmithAndHeapIdentities = async (
    id: string,
    flagsmithTraits: Record<string, string | number | boolean | null>,
  ) => {
    await flagsmith.identify(id, flagsmithTraits);
    if (ENV.NAME === PROD) {
      // Setup heap identity
      window.heap && window.heap.identify(id);
    }
  };

  setRegistrationForm = ({ name, email }: Pick<User, "name" | "email">) => {
    this.registrationForm = { name, email };
  };

  clearRegistrationForm = () => {
    this.registrationForm = null;
  };

  clearStore = () => {
    this.unsubscribeUserInvitations();
    this.currentUser = undefined;
    // currentUserImg = localAvatar;
    this.currentPhone = "";
    this.currentEmail = "";
    this.currentId = undefined;
    this.currentIDP = undefined;
    this.registrationForm = null;
    this.uiState = {};
  };

  forgetUser = () => {
    this.currentUser = undefined;
    this.currentId = "";
  };

  addHubspot = async (endpoint: string, variables: HubspotField[]) => {
    const hubSpotUtk = getHubSpotUtk();
    try {
      return await axios.post(`${ENV.HUBSPOT}${endpoint}`, [
        ...variables,
        ...(hubSpotUtk ? [{ name: "hutk", value: hubSpotUtk }] : []),
      ]);
    } catch (e) {
      return { status: 500 };
    }
  };

  setHubspotUser = ({
    id,
    email,
    name,
    isVerified = false,
  }: HubspotFormProps) => {
    const newUserData: HubspotUserProps = JSON.parse(
      localStorage.getItem(NEW_USER_DATA) || "{}",
    );
    const { utm_params, is_invited, wants_subscription } = newUserData;
    const { first = "undefined", last = "undefined" } = parseFullName(name);

    const hubspotForm: HubspotField[] = [
      { name: "email", value: email },
      { name: "source", value: "cloud-v2-console" },
      { name: "name", value: name },
      { name: "firstname", value: first },
      { name: "lastname", value: last },
      { name: "cloud_trial_created_from_invite_", value: is_invited || "" },
      { name: "timescale_user_id", value: id },
      { name: "is_verified_user", value: is_invited || isVerified },
      { name: "utm_campaign", value: utm_params?.utm_campaign || "" },
      { name: "utm_content", value: utm_params?.utm_content || "" },
      { name: "utm_keyword", value: utm_params?.utm_keyword || "" },
      { name: "utm_medium", value: utm_params?.utm_medium || "" },
      { name: "utm_source", value: utm_params?.utm_source || "" },
      { name: "utm_term", value: utm_params?.utm_term || "" },
      { name: "ga_client_id", value: getGaClientId() || "" },
    ];
    if (wants_subscription) {
      hubspotForm.push({
        name: "gdpr",
        value: [
          {
            id: FORM_SUBSCRIPTION_IDS["forge"],
            value: wants_subscription,
          },
        ],
      });
    }

    return hubspotForm;
  };

  sendHubspotEmbeddedForm = async ({
    id,
    email,
    name,
    isVerified = false,
  }: HubspotFormProps) => {
    const form = document.querySelector(
      ".hbspt-form form",
    ) as HTMLFormElement | null;

    if (form) {
      const hubspotUser = this.setHubspotUser({ id, email, name, isVerified });

      const setValue = (inputName: string, value: any) => {
        const input = form?.querySelector(
          `input[name="${inputName}"]`,
        ) as HTMLInputElement | null;
        if (input) {
          if (input.type === "checkbox") {
            input.checked = value;
          } else {
            input.value = value;
          }
        }
      };

      if (hubspotUser && form) {
        hubspotUser.map(({ name, value }) => setValue(name, value));

        form.submit();
      }
    }
  };

  sendHubspotUser = async ({
    id,
    email,
    name,
    isVerified = false,
  }: HubspotFormProps) => {
    const hubspotUser = this.setHubspotUser({ id, email, name, isVerified });

    await this.addHubspot("timescale-forge", hubspotUser);
  };

  updateContactHubspot = async (email: string, variables: HubspotField[]) => {
    try {
      return await axios.post(
        `${ENV.HUBSPOT}hubspot/contact/${email}`,
        variables,
      );
    } catch (e) {
      return { status: 500 };
    }
  };

  _createPersonaVariables = ({
    uiState,
    userId,
  }: {
    uiState: { [k: string]: any };
    userId?: UpdateUserPersonaMutationVariables["user_id"];
  }) => ({
    user_id: userId || this.currentId,
    uiState: JSON.stringify(uiState || this.uiState),
  });

  _createUserPersona = async (
    variables: CreateUserPersonaMutationVariables,
  ) => {
    const { data, errors } = await client.mutate({
      variables,
      mutation: CREATE_USER_PERSONA,
    });
    return { data, errors };
  };

  _shouldUpdateFlagsmithId = ({ uiState }: { [k: string]: any }) =>
    //ex: foo@timescale.com___aw30-fewe-12324
    !uiState?.flagsmithId || !/.+@.+\..+___[\d\w-]+$/.test(uiState.flagsmithId);

  setUserPersona = async ({
    uiState = this.uiState || {},
    userEmail,
    userId = this.currentId,
  }: {
    uiState?: { [k: string]: any };
    userEmail?: string;
    userId?: UpdateUserPersonaMutationVariables["user_id"];
  } = {}) => {
    if (this._shouldUpdateFlagsmithId({ uiState })) {
      uiState.flagsmithId = (userEmail || this.currentEmail) + "___" + uuidv4();
    }
    try {
      const variables = this._createPersonaVariables({
        uiState,
        userId,
      });
      const { data: updateData, errors: updateErrors } = await client.mutate({
        variables,
        mutation: UPDATE_USER_PERSONA,
      });
      if (updateData) {
        this._updateStoreUiState({ uiState });
      }
      if (updateErrors) {
        const { errors: createErrors } =
          await this._createUserPersona(variables);
        let errorMsg = "UPDATE PERSONA FAILED";
        if (createErrors) {
          errorMsg = "UPDATE PERSONA FAILED; CREATE PERSONA FAILED";
          Logger.error(errorMsg, {
            source: "network",
            uiState,
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  mergeUiState = async (newUiState: { [k: string]: any }) => {
    this._removeOutdatedInfoFromUiState();
    await this.setUserPersona({ uiState: merge({}, this.uiState, newUiState) });
  };

  _removeOutdatedInfoFromUiState = () => {
    window.localStorage.removeItem("guided_demo");

    const allServicesMap = new Map(
      serviceStore.allServices.map((service: Service) => [
        service.id,
        service.name,
      ]),
    );

    const deleteOutdatedServiceKeys = (uiStateKey: string) => {
      Object.keys(this.uiState[uiStateKey] || {}).forEach((key: string) => {
        if (!allServicesMap.has(key)) {
          delete this.uiState[uiStateKey][key];
        }
      });
    };

    [SERVICE_OVERVIEW_SETTINGS, HIDE_UPGRADE_CARD, COMPRESSION_USED].forEach(
      (key) => deleteOutdatedServiceKeys(key),
    );
  };

  subscribeUserInvitations = async () => {
    try {
      this.unsubscribeUserInvitations();
      this.userInvitationsSubscription = client.watchQuery({
        query: GET_PROJECT_INVITES_FOR_USER,
        pollInterval: 60000,
        fetchPolicy: "no-cache",
      });

      runInAction(
        () =>
          (this.refreshUserInvitations =
            this.userInvitationsSubscription?.subscribe(({ data, errors }) => {
              if (errors) {
                notificationStore.showErrorToaster(
                  "There was an error retrieving your invitations. Please try again.",
                );
              } else if (data) {
                const invitations = data.getProjectInvitesForUser.map(
                  (invite: ProjectInvite) => {
                    return {
                      id: invite.id,
                      inviter:
                        invite?.inviter?.name === ""
                          ? "A Timescale User"
                          : invite?.inviter?.name,
                      inviterEmail: invite?.inviter?.email,
                      projectName: invite.project.name,
                      declined: invite.declined,
                      hash: invite.hash,
                      created: invite.created,
                    };
                  },
                );

                runInAction(
                  () =>
                    (this.pendingInvitations = invitations
                      .filter((invite: ProjectInvite) => !invite.declined)
                      .reverse()),
                );
              }
            })),
      );
    } catch (e) {
      console.error("subscribeUserInvitations: Network Error", e);
    }
  };

  unsubscribeUserInvitations = () => {
    this.userInvitationsSubscription?.stopPolling();
    this.refreshUserInvitations?.unsubscribe();
    this.userInvitationsSubscription = undefined;
    this.refreshUserInvitations = undefined;
  };

  confirmProjectInvitation = async ({
    id,
    action,
    hash,
  }: {
    id: JoinProjectMutationVariables["inviteId"];
    action: keyof typeof ACTION;
    hash: JoinProjectMutationVariables["hash"];
  }) => {
    const projectName = this.pendingInvitations.filter(
      (invitation: ProjectInvite) => invitation.id === id,
    )[0]["projectName"];

    const toasterText = `You ${
      action === ACTION.ACCEPT ? "accepted" : "declined"
    } the invitation to join ${projectName} project.`;

    if (action === ACTION.ACCEPT) {
      return await this.acceptProjectInvitation(id, hash, toasterText);
    } else if (action === ACTION.DECLINE) {
      this.declineProjectInvitation(id, toasterText);
    }
  };

  declineProjectInvitation = async (
    id: DeclineProjectInviteMutationVariables["inviteId"],
    toasterText: string,
  ) => {
    try {
      const { data: declineProjectSuccess, errors: declineProjectErrors } =
        await client.mutate({
          mutation: DECLINE_PROJECT_INVITE,
          variables: {
            inviteId: id,
          },
        });

      if (declineProjectSuccess) {
        this.subscribeUserInvitations();
        notificationStore.showSuccessToaster(toasterText);
      } else if (declineProjectErrors) {
        notificationStore.showErrorToaster(
          "There was a problem declining the invite. Please try again.",
        );
      }
    } catch (e) {
      console.error("Error: DECLINE_PROJECT_INVITE", e);
    }
  };

  acceptProjectInvitation = async (
    id: JoinProjectMutationVariables["inviteId"],
    hash: JoinProjectMutationVariables["hash"],
    toasterText: string,
  ) => {
    try {
      const { data: joinProjectSuccess, errors: joinProjectErrors } =
        await client.mutate({
          mutation: JOIN_PROJECT,
          variables: {
            inviteId: id,
            hash,
          },
        });

      if (joinProjectSuccess) {
        this.subscribeUserInvitations();
        notificationStore.showSuccessToaster(toasterText);
        await projectsStore.getAllProjects();
        return joinProjectSuccess.joinProject;
      } else if (joinProjectErrors) {
        notificationStore.showErrorToaster(
          "There was a problem accepting the invite. Please try again.",
        );
      }
      const joinedProjectId = joinProjectSuccess.joinProject;
      return joinedProjectId;
    } catch (e) {
      console.error("Error: JOIN_PROJECT", e);
    }
  };

  leaveProject = async (
    projectId: LeaveProjectMutationVariables["projectId"] | null,
    currentProjectName: Project["name"],
  ) => {
    if (!projectId) {
      console.error("Project ID not found");
      return;
    }
    try {
      const { data } = await client.mutate({
        variables: { projectId },
        mutation: LEAVE_PROJECT,
      });

      if (data) {
        await projectsStore.getAllProjects();
        notificationStore.showSuccessToaster(
          `You have successfully left ${currentProjectName}.`,
        );
      } else {
        notificationStore.showErrorToaster(
          `There was a problem leaving ${currentProjectName}. Please try again.`,
        );
      }
    } catch (e) {
      console.error("leaveProject: Network error", e);
    }
  };

  getUser = async () => {
    try {
      const { data } = await client.query({
        query: GET_USER,
      });
      if (data) {
        const user = data[GET_USER__TYPE];
        this.updateId(user.id);
        this.setUser(user);

        const uiState =
          typeof JSON.parse(user?.uiState) === "string"
            ? {}
            : JSON.parse(user?.uiState);
        if (this._shouldUpdateFlagsmithId({ uiState })) {
          await this.setUserPersona({ uiState });
        } else {
          this._updateStoreUiState({ uiState });
        }
        return user;
      }
    } catch (e) {
      console.error(e);
      this.forgetUser();
    } finally {
      runInAction(() => {
        this.storeStatus = READY;
      });
    }
  };

  resendVerifyBillingEmail = async ({
    billingEmailId,
    billingAccountId,
  }: ResendVerifyBillingEmailAddressMutationVariables) => {
    try {
      if (!billingAccountId) {
        console.warn("Request aborted: Billing account is null");
      }
      const { data, errors } = await client.mutate({
        variables: {
          billingEmailId,
          billingAccountId,
        },
        mutation: RESEND_VERIFY_BILLING_EMAIL,
      });

      if (data) {
        return data;
      } else if (errors) {
        notificationStore.showErrorToaster(
          "There was a problem resending the verification email. Please try again.",
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  verifyEmail = async ({ email, hash }: VerifyEmailMutationVariables) => {
    this.clearStore();
    const { data, errors } = await client.mutate({
      variables: {
        email,
        hash,
      },
      mutation: VERIFY_EMAIL,
    });
    await runInAction(async () => {
      if (data) {
        const { userID: id } = data.verifyEmail;
        authStore.setAccount({ id, email });
        await this.setUserPersona({
          userId: id,
          userEmail: email,
        });
      }
    });
    return { data, errors };
  };

  /*
  When we run both Cloud 1.0 and Cloud 2.0 simultaneously Cloud 1.0 loads first and triggers verifyEmail.
  Then Flagsmith kicks in, Cloud 2.0 loads and triggers verifyEmail again. Since the query succeeds only on the first
  attempt the workaround was added.
  */
  verifyBillingEmail = ({
    billingEmailId,
    hash,
  }: VerifyBillingEmailAddressMutationVariables) => {
    if (!this.verifyBillingEmailAddressPromise) {
      runInAction(() => {
        this.verifyBillingEmailAddressPromise = client.mutate({
          variables: {
            billingEmailId,
            hash,
          },
          mutation: VERIFY_BILLING_EMAIL,
        });
      });
    }
    return this.verifyBillingEmailAddressPromise;
  };

  createSupportTicket = async ({
    description,
    email,
    impact,
    isProduction,
    projectId,
    services,
    subject,
  }: Pick<
    SupportTicketInput,
    | "description"
    | "email"
    | "impact"
    | "isProduction"
    | "projectId"
    | "services"
    | "subject"
  >) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          projectId,
          services,
          subject,
          description,
          email,
          userId: this.currentId,
          name: this.currentUser,
          isProduction,
          impact,
        },
        mutation: CREATE_SUPPORT_TICKET,
      });

      if (errors) {
        notificationStore.showErrorToaster(
          "There was a problem sending the support ticket. Please try again.",
        );
      }
      return { data: data[CREATE_SUPPORT_TICKET__TYPE], errors };
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  postZap = async (variables: Record<string, string | number | boolean>) => {
    try {
      const res = await axios.post(
        `${ENV.WEB_ENDPOINT}/${ZAPIER_ONBOARDING_ENDPOINT}`,
        variables,
      );

      if (!res?.data || res?.data?.errors) {
        throw new Error(
          "Error posting to Zapier. zapUrl: " + variables?.zapUrl,
        );
      }
    } catch (_) {
      throw new Error("Error posting to Zapier. zapUrl: " + variables?.zapUrl);
    }
  };

  isUtmCompain = ({ compainName }: { compainName: UTM_CAMPAIGNS }) => {
    const campaign =
      JSON.parse(localStorage?.getItem(NEW_USER_DATA) || "{}").utm_params || {};
    return campaign?.utm_campaign === compainName;
  };
}

const userStore = new UserStore();

export default userStore;
