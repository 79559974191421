import { useState } from "react";
import { IconButton as ChakraIconButton } from "@chakra-ui/react";
import Icon, { IconSize } from "../icon/Icon";
import { IconButtonProps } from "./buttonTypes";
import { getStyles } from "./utils";

//Note that Chakra's IconButton uses the component theme of Button **and it can't be overriden with useStyleProps**
const sizeMap: { [x: string]: IconSize } = {
  sm: "16",
  md: "16",
  lg: "20",
  xl: "32",
};

const IconButton = ({
  size = "sm",
  isDisabled,
  isLoading = false,
  variant,
  iconName,
  iconNameWhenClicked,
  iconFill,
  label,
  className,
  onClick = () => {},
  buttonRef,
  ...rest
}: IconButtonProps) => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const handleMouseDown = () => {
    setIsMouseDown(true);
  };
  const handleMouseUp = () => {
    if (isMouseDown) {
      onClick();
      setIsMouseDown(false);
    }
  };
  const handleMouseLeave = () => {
    setIsMouseDown(false);
  };

  const interiorIcon = (
    <Icon
      name={
        isLoading
          ? "status/ProgressAnimated"
          : isMouseDown && iconNameWhenClicked
            ? iconNameWhenClicked
            : iconName
      }
      {...(iconFill ? { fill: iconFill } : {})}
      size={sizeMap[size]}
    />
  );

  return (
    <ChakraIconButton
      className={"icon-button" + className ? " " + className : ""}
      variant={variant as string}
      isDisabled={isDisabled}
      size={size}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      sx={{
        ...getStyles(isLoading),
      }}
      aria-label={isLoading ? label + " loading" : label}
      {...rest}
      icon={interiorIcon}
      ref={buttonRef}
    />
  );
};

export default IconButton;
