import authStore from "./authStore";
import commonStore from "./commonStore";
import notificationStore from "./notificationStore";
import userStore from "./userStore";
import projectsStore from "./projectsStore";
import productStore from "./productStore";
import serviceStore from "./serviceStore";
import paymentMethodsStore from "./paymentMethodsStore";
import featuresStore from "./featuresStore";
import emailStore from "./emailStore";
import databaseParametersStore from "./databaseParametersStore";
import serviceResizeStore from "./serviceResizeStore";
import exporterStore from "./exporterStore";
import queryStatsStore from "./queryStatsStore";
import { useContext } from "react";
import { MobXProviderContext } from "mobx-react";

export { AuthStore } from "./authStore";
export { CommonStore } from "./commonStore";
export { NotificationStore } from "./notificationStore";
export { UserStore } from "./userStore";
export { ProjectsStore } from "./projectsStore";
export { ServiceStore } from "./serviceStore";
export { ProductStore } from "./productStore";
export { EmailStore } from "./emailStore";
export { DatabaseParametersStore } from "./databaseParametersStore";
export { ServiceResizeStore } from "./serviceResizeStore";
export { ExporterStore } from "./exporterStore";
export { QueryStatsStore } from "./queryStatsStore";

export const stores = {
  authStore,
  commonStore,
  notificationStore,
  userStore,
  projectsStore,
  productStore,
  serviceStore,
  paymentMethodsStore,
  featuresStore,
  emailStore,
  databaseParametersStore,
  serviceResizeStore,
  exporterStore,
  queryStatsStore,
};

export default stores;

export function useStores() {
  return useContext(MobXProviderContext);
}

export { READY, LOADING, ERROR, STALE, NOT_INITIATED } from "./constants";
