import { Flex, StyleProps, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import Icon, { Icons } from "../../icon/Icon";
import { VerticalNavItemProps } from "./navTypes";
import Hover from "../../tooltip/Hover/Hover";
import { useContext } from "react";
import { LeftNavContext } from "layouts/navigation/NavigationProvider";

// A HOC that wraps the children in a NavLink give an `item.to`.
// Meant to address some NavItems using `onSelect` instead of `to`.
const BuildLink = ({
  children,
  item,
}: {
  children: JSX.Element | JSX.Element[];
  item: VerticalNavItemProps;
}) => {
  if (!item.to) return <>{children}</>;

  return (
    <NavLink to={item.to} style={{ marginTop: 0 }}>
      {children}
    </NavLink>
  );
};

export type VerticalNavMenuItemProps = StyleProps & {
  item: VerticalNavItemProps;
  isActive?: boolean;
  isChevron?: boolean;
  truncate?: boolean;
};

export const VerticalNavMenuItem = ({
  item,
  isActive,
  isChevron,
  truncate = true,
  ...styleProps
}: VerticalNavMenuItemProps) => {
  const { isLeftNavMinified, setIsMenuOpen } = useContext(LeftNavContext);
  const { id, icon, label, labelText, hoverText, onSelect = () => {} } = item;
  const iconComponent =
    typeof icon === "string" ? (
      <Icon
        name={item.icon as Icons}
        size="20"
        fill={isActive ? "grayscale.900" : "grayscale.700"}
      />
    ) : (
      icon
    );
  return (
    <BuildLink item={item}>
      <Hover
        theme="menu"
        placement="right"
        content={hoverText || label}
        disabled={isActive || (!isLeftNavMinified && !hoverText) || !label}
      >
        <Flex
          direction="column"
          bg={isActive ? "grayscale.100" : "grayscale.0"}
          borderColor={isActive ? "grayscale.200" : "grayscale.0"}
          borderWidth="1px"
          borderRadius="8px"
          px="8px"
          py={labelText && !isLeftNavMinified ? "4px" : "8px"}
          cursor="pointer"
          width="fill-available"
          _hover={{
            "&>p": {
              color: "grayscale.900",
            },
            "&>div>p": {
              color: "grayscale.900",
            },
            "&>p>p": {
              color: "grayscale.900",
            },
            "&>div>div>svg": { fill: "grayscale.900" },
          }}
          onClick={() => {
            onSelect();
            setIsMenuOpen(false);
          }}
          data-cy={`${id}-button`}
          overflow="hidden"
          {...styleProps}
        >
          <Flex alignItems="center" gap="8px">
            {iconComponent}
            {!isLeftNavMinified && (
              <Text
                color={isActive ? "grayscale.900" : "grayscale.700"}
                textStyle={isActive ? "baseBold" : "base"}
                whiteSpace="nowrap"
                overflow={truncate ? "hidden" : "visible"}
                textOverflow="ellipsis"
              >
                {label}
              </Text>
            )}
            {!isLeftNavMinified && isChevron && (
              <Icon
                name="actions/Chevron/Right"
                ml="auto"
                fill={isActive ? "grayscale.900" : "grayscale.700"}
              />
            )}
          </Flex>
          {!isLeftNavMinified && labelText}
        </Flex>
      </Hover>
    </BuildLink>
  );
};
