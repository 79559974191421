import { gql } from "@apollo/client";

const CREATE_PROJECT = gql`
  mutation CreateProject($name: String!, $trialDays: Int) {
    createProject(data: { name: $name, trialDays: $trialDays }) {
      id
      name
    }
  }
`;

const JOIN_PROJECT = gql`
  mutation JoinProject($inviteId: ID!, $hash: String!) {
    joinProject(data: { inviteId: $inviteId, hash: $hash })
  }
`;

const DECLINE_PROJECT_INVITE = gql`
  mutation DeclineProjectInvite($inviteId: ID!) {
    declineProjectInvite(inviteId: $inviteId)
  }
`;

const LEAVE_PROJECT = gql`
  mutation LeaveProject($projectId: ID!) {
    leaveProject(projectId: $projectId)
  }
`;

const CANCEL_PROJECT_INVITE = gql`
  mutation CancelProjectInvite($inviteId: ID!) {
    cancelProjectInvite(inviteId: $inviteId)
  }
`;

const REVOKE_PROJECT_MEMBER = gql`
  mutation RevokeProjectMember($projectId: ID!, $userId: ID!) {
    revokeProjectMember(data: { projectId: $projectId, userId: $userId })
  }
`;

const INVITE_PROJECT_MEMBER = gql`
  mutation InviteProjectMember($projectId: ID!, $email: String!) {
    inviteProjectMember(data: { projectId: $projectId, email: $email })
  }
`;

const RESEND_INVITE_PROJECT_MEMBER = gql`
  mutation ResendInviteProjectMember($inviteId: ID!) {
    resendInviteProjectMember(inviteId: $inviteId)
  }
`;

const CREATE_SERVICE__TYPE = `createService`;
const CREATE_SERVICE = gql`
  mutation CreateService(
    $projectId: ID!
    $name: String!
    $type: Type!
    $resourceConfig: ResourceConfig
    $vpcId: ID
    $enableConnectionPooler: Boolean
    $regionCode: String!
    $forkConfig: ForkConfig
    $demoID: Int
    $environmentTag: ServiceEnvironment
    $extensions: [String]
  ) {
    createService(
      data: {
        projectId: $projectId
        name: $name
        type: $type
        resourceConfig: $resourceConfig
        vpcId: $vpcId
        enableConnectionPooler: $enableConnectionPooler
        regionCode: $regionCode
        forkConfig: $forkConfig
        demoID: $demoID
        environmentTag: $environmentTag
        extensions: $extensions
      }
    ) {
      service {
        name
        id
        projectId
        type
        isReadOnly
        regionCode
        spec {
          ... on TimescaleDBServiceSpec {
            hostname
            username
            port
            defaultDBName
            connectionPoolerEnabled
            poolerHostName
            poolerPort
          }
        }
        resources {
          id
          spec {
            ... on ResourceNode {
              milliCPU
              memoryGB
              storageGB
              replicaCount
              syncReplicaCount
            }
          }
        }
        status
        vpcId
        vpcEndpoint {
          ... on Endpoint {
            projectId
            serviceId
            vpcId
            host
            port
          }
        }
        metrics {
          ... on ResourceMetrics {
            memoryMB
            storageMB
            milliCPU
          }
        }
        primaryOrdinal
        replicaOrdinals
        replicaStatus
        forkedFromId {
          serviceId
          projectId
        }
        created
      }
      initialPassword
    }
  }
`;

const CREATE_SERVICE_FORK = gql`
  mutation CreateServiceFork(
    $projectId: ID!
    $name: String!
    $type: Type!
    $resourceConfig: ResourceConfig
    $vpcId: ID
    $enableConnectionPooler: Boolean
    $regionCode: String!
    $forkConfig: ForkConfig!
    $environmentTag: ServiceEnvironment
  ) {
    createService(
      data: {
        projectId: $projectId
        name: $name
        type: $type
        resourceConfig: $resourceConfig
        vpcId: $vpcId
        enableConnectionPooler: $enableConnectionPooler
        regionCode: $regionCode
        forkConfig: $forkConfig
        environmentTag: $environmentTag
      }
    ) {
      service {
        name
        id
        projectId
        type
        isReadOnly
        regionCode
        spec {
          ... on TimescaleDBServiceSpec {
            hostname
            username
            port
            defaultDBName
            connectionPoolerEnabled
            poolerHostName
            poolerPort
          }
        }
        resources {
          id
          spec {
            ... on ResourceNode {
              milliCPU
              memoryGB
              storageGB
              replicaCount
            }
          }
        }
        status
        vpcId
        vpcEndpoint {
          ... on Endpoint {
            projectId
            serviceId
            vpcId
            host
            port
          }
        }
        metrics {
          ... on ResourceMetrics {
            memoryMB
            storageMB
            milliCPU
          }
        }
        forkedFromId {
          serviceId
          projectId
          isStandby
        }
        created
      }
      initialPassword
    }
  }
`;

const SET_REPLICA_COUNT = gql`
  mutation SetReplicaCount(
    $serviceId: ID!
    $projectId: ID!
    $replicaCount: Int!
    $synchronousReplicaCount: Int
    $allReplicasAreSync: Boolean
  ) {
    setReplicaCount(
      data: {
        serviceId: $serviceId
        projectId: $projectId
        replicaCount: $replicaCount
        synchronousReplicaCount: $synchronousReplicaCount
        allReplicasAreSync: $allReplicasAreSync
      }
    )
  }
`;

const UPGRADE_SERVICE_POSTGRES_MAJOR_VERSION = gql`
  mutation UpgradePostgresMajorVersion(
    $projectId: ID!
    $serviceId: ID!
    $postgresVersion: Int!
  ) {
    upgradePostgresMajorVersion(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        postgresVersion: $postgresVersion
      }
    )
  }
`;

// const CREATE_RESOURCE = gql`
//   mutation CreateResource($serviceId: ID!, $name: String!, $type: String!) {
//     createResource(data: { serviceId: $serviceId, name: $name, type: $type }) {
//       id
//       serviceId
//       name
//       type
//     }
//   }
// `;

const CREATE_USER__TYPE = "createUser";
const CREATE_USER = gql`
  mutation CreateUser(
    $name: String!
    $email: String!
    $password: String!
    $passwordConfirm: String!
    $recaptchaToken: String!
    $inviteHash: String
  ) {
    createUser(
      data: {
        name: $name
        email: $email
        password: $password
        passwordConfirm: $passwordConfirm
        recaptchaToken: $recaptchaToken
        inviteHash: $inviteHash
      }
    ) {
      name
      email
      id
      created
    }
  }
`;

const SET_ENVIRONMENT_TAG__TYPE = "setServiceEnvironmentTag";
const SET_ENVIRONMENT_TAG = gql`
  mutation SetServiceEnvironmentTag(
    $projectId: ID!
    $serviceId: ID!
    $environment: ServiceEnvironment!
  ) {
    setServiceEnvironmentTag(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        environment: $environment
      }
    )
  }
`;

const CREATE_PAYMENT_METHOD__TYPE = "createPaymentMethod";
const CREATE_PAYMENT_METHOD = gql`
  mutation CreatePaymentMethod($billingAccountId: ID!, $isPrimary: Boolean!) {
    createPaymentMethod(
      billingAccountId: $billingAccountId
      isPrimary: $isPrimary
    )
  }
`;

const UPDATE_PAYMENT_METHOD__TYPE = "updatePaymentMethod";

const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdatePaymentMethod(
    $billingAccountId: ID!
    $paymentMethodId: ID!
    $addressInfo: AddressInfoInput
    $cardExp: CardExpInput!
  ) {
    updatePaymentMethod(
      billingAccountId: $billingAccountId
      paymentMethodId: $paymentMethodId
      addressInfo: $addressInfo
      cardExp: $cardExp
    ) {
      id
      isPrimary
      cardInfo {
        last4
      }
      addressInfo {
        city
        state
        country
        line1
        line2
        postalCode
      }
    }
  }
`;

const DELETE_PAYMENT_METHOD__TYPE = "deletePaymentMethod";
const DELETE_PAYMENT_METHOD = gql`
  mutation DeletePaymentMethod($billingAccountId: ID!, $paymentMethodId: ID!) {
    deletePaymentMethod(
      billingAccountId: $billingAccountId
      paymentMethodId: $paymentMethodId
    ) {
      id
    }
  }
`;

const SET_PRIMARY_PAYMENT_METHOD = gql`
  mutation SetPrimaryPaymentMethod(
    $billingAccountId: ID!
    $paymentMethodId: ID!
  ) {
    setPrimaryPaymentMethod(
      data: {
        billingAccountId: $billingAccountId
        paymentMethodId: $paymentMethodId
      }
    )
  }
`;

const CREATE_BILLING_EMAIL = gql`
  mutation CreateBillingEmailAddress($email: String!, $billingAccountId: ID!) {
    createBillingEmailAddress(
      data: { billingAccountId: $billingAccountId, email: $email }
    ) {
      id
      email
      billingAccountId
      created
      activated
    }
  }
`;

const DELETE_BILLING_EMAIL = gql`
  mutation DeleteBillingEmailAddress(
    $billingEmailId: ID!
    $billingAccountId: ID!
  ) {
    deleteBillingEmailAddress(
      data: {
        billingEmailId: $billingEmailId
        billingAccountId: $billingAccountId
      }
    )
  }
`;

const VERIFY_BILLING_EMAIL = gql`
  mutation VerifyBillingEmailAddress($billingEmailId: ID!, $hash: String!) {
    verifyBillingEmailAddress(
      data: { billingEmailId: $billingEmailId, hash: $hash }
    )
  }
`;

const RESEND_VERIFY_BILLING_EMAIL = gql`
  mutation ResendVerifyBillingEmailAddress(
    $billingEmailId: ID!
    $billingAccountId: ID!
  ) {
    resendVerifyBillingEmailAddress(
      data: {
        billingEmailId: $billingEmailId
        billingAccountId: $billingAccountId
      }
    )
  }
`;

const CANCEL_PENDING_PAYMENT_DELETION = gql`
  mutation CancelPendingPaymentDeletion(
    $billingAccountId: ID!
    $paymentMethodId: ID!
  ) {
    cancelPendingPaymentDeletion(
      data: {
        billingAccountId: $billingAccountId
        paymentMethodId: $paymentMethodId
      }
    )
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $oldPassword: String!
    $newPassword: String!
    $newPasswordConfirm: String!
    $trustChallenge: String
    $trustToken: String
  ) {
    changePassword(
      data: {
        oldPassword: $oldPassword
        newPassword: $newPassword
        newPasswordConfirm: $newPasswordConfirm
        trustChallenge: $trustChallenge
        trustToken: $trustToken
      }
    )
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $password: String!
    $passwordConfirm: String!
    $token: String!
    $trustChallenge: String
    $trustToken: String
  ) {
    resetPassword(
      data: {
        password: $password
        passwordConfirm: $passwordConfirm
        token: $token
        trustChallenge: $trustChallenge
        trustToken: $trustToken
      }
    )
  }
`;

const RESET_SERVICE_PASSWORD = gql`
  mutation ResetServicePassword(
    $serviceId: ID!
    $projectId: ID!
    $password: String!
    $passwordType: PasswordType!
  ) {
    resetServicePassword(
      data: {
        serviceId: $serviceId
        projectId: $projectId
        password: $password
        passwordType: $passwordType
      }
    )
  }
`;

const SEND_NEW_PASSWORD = gql`
  mutation SendPasswordReset($email: String!) {
    sendPasswordReset(email: $email)
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($name: String!, $phone: String) {
    updateUser(data: { name: $name, phone: $phone }) {
      id
      name
      email
      phone
      created
      origin
    }
  }
`;

const VERIFY_EMAIL = gql`
  mutation VerifyEmail($email: String!, $hash: String!) {
    verifyEmail(data: { email: $email, hash: $hash }) {
      status
      userID
      userToken
      user {
        id
        name
        email
        phone
        created
        managedByIdp
      }
    }
  }
`;

const RESEND_VERIFY_EMAIL = gql`
  mutation ResendVerifyEmail($email: String!) {
    resendVerifyEmail(email: $email)
  }
`;

const DELETE_SERVICE = gql`
  mutation DeleteService($serviceId: ID!, $projectId: ID!) {
    deleteService(data: { serviceId: $serviceId, projectId: $projectId }) {
      id
      name
    }
  }
`;

const TOGGLE_SERVICE = gql`
  mutation ToggleService($serviceId: ID!, $projectId: ID!, $status: Status!) {
    toggleService(
      data: { serviceId: $serviceId, projectId: $projectId, status: $status }
    ) {
      id
      name
      spec {
        ... on TimescaleDBServiceSpec {
          status
        }
      }
    }
  }
`;

const TOGGLE_POPSQL_INTEGRATION = gql`
  mutation TogglePopSQLIntegration(
    $projectId: ID!
    $serviceId: ID!
    $enable: Boolean!
  ) {
    togglePopSQLIntegration(
      data: { projectId: $projectId, serviceId: $serviceId, enable: $enable }
    )
  }
`;

const RESIZE_INSTANCE = gql`
  mutation ResizeInstance(
    $serviceId: ID!
    $projectId: ID!
    $config: ResourceConfig!
  ) {
    resizeInstance(
      data: { serviceId: $serviceId, projectId: $projectId, config: $config }
    )
  }
`;

const SEND_SUPPORT_EMAIL = gql`
  mutation SendSupportEmail(
    $serviceId: ID!
    $projectId: ID!
    $subject: String!
    $formBody: String!
    $email: String!
    $userId: ID!
    $name: String!
    $destinations: [String!]
  ) {
    sendSupportEmail(
      data: {
        serviceId: $serviceId
        projectId: $projectId
        subject: $subject
        formBody: $formBody
        email: $email
        userId: $userId
        name: $name
        destinations: $destinations
      }
    )
  }
`;

const SET_ADDRESS_INFO__TYPE = "setAddressInfo";

const SET_ADDRESS_INFO = gql`
  mutation SetAddressInfo(
    $billingAccountId: ID!
    $companyAddress: AddressInfoInput!
  ) {
    setAddressInfo(
      billingAccountId: $billingAccountId
      companyAddress: $companyAddress
    ) {
      companyAddress {
        companyName
        line1
        line2
        city
        state
        country
        postalCode
      }
    }
  }
`;

const SET_POSTGRES_PARAMETERS = gql`
  mutation SetPostgresParameters(
    $serviceId: ID!
    $projectId: ID!
    $numericParameters: [SetNumericParameter!]
    $stringParameters: [SetStringParameter!]
  ) {
    setPostgresParameters(
      data: {
        serviceId: $serviceId
        projectId: $projectId
        numericParameters: $numericParameters
        stringParameters: $stringParameters
      }
    ) {
      name
      errorMessage
    }
  }
`;

const RESET_POSTGRES_PARAMETERS = gql`
  mutation ResetPostgresParameters($serviceId: ID!, $projectId: ID!) {
    resetPostgresParameters(
      data: { serviceId: $serviceId, projectId: $projectId }
    )
  }
`;

const CHANGE_MAINTENANCE_WINDOW__TYPE = "setMaintenanceWindow";
const CHANGE_MAINTENANCE_WINDOW = gql`
  mutation ChangeMaintenanceWindow(
    $projectId: ID!
    $serviceId: ID!
    $start: Time!
    $durationInSeconds: Int!
  ) {
    setMaintenanceWindow(
      data: {
        serviceId: $serviceId
        projectId: $projectId
        start: $start
        durationInSeconds: $durationInSeconds
      }
    )
  }
`;

const CREATE_USER_PERSONA__TYPE = `createUserPersona`;
const CREATE_USER_PERSONA = gql`
  mutation CreateUserPersona($user_id: ID!, $uiState: String!) {
    createUserPersona(data: { user_id: $user_id, uiState: $uiState }) {
      user_id
      uiState
    }
  }
`;

const UPDATE_USER_PERSONA__TYPE = `updateUserPersona`;
const UPDATE_USER_PERSONA = gql`
  mutation UpdateUserPersona($user_id: ID!, $uiState: String!) {
    updateUserPersona(data: { user_id: $user_id, uiState: $uiState }) {
      user_id
      uiState
    }
  }
`;
const CREATE_VPC = gql`
  mutation CreateVpc(
    $projectId: ID!
    $cidr: String!
    $cloudProvider: CloudProvider!
    $name: String!
    $regionCode: String!
  ) {
    createVpc(
      data: {
        projectId: $projectId
        cidr: $cidr
        cloudProvider: $cloudProvider
        name: $name
        regionCode: $regionCode
      }
    ) {
      id
    }
  }
`;

const RENAME_VPC = gql`
  mutation RenameVPC($projectId: ID!, $forgeVpcId: ID!, $newName: String!) {
    renameVpc(
      data: {
        projectId: $projectId
        forgeVpcId: $forgeVpcId
        newName: $newName
      }
    )
  }
`;

const RENAME_SERVICE = gql`
  mutation RenameService($projectId: ID!, $serviceId: ID!, $newName: String!) {
    renameService(
      data: { projectId: $projectId, serviceId: $serviceId, newName: $newName }
    )
  }
`;

const RENAME_PROJECT = gql`
  mutation RenameProject($projectId: ID!, $name: String!) {
    renameProject(data: { projectId: $projectId, name: $name }) {
      id
      name
    }
  }
`;

const OPEN_PEER_REQUEST = gql`
  mutation OpenPeerRequest(
    $projectId: ID!
    $forgeVpcId: ID!
    $cloudProvider: CloudProvider!
    $peerVpc: InputPeerVPC!
  ) {
    openPeerRequest(
      data: {
        projectId: $projectId
        forgeVpcId: $forgeVpcId
        cloudProvider: $cloudProvider
        peerVpc: $peerVpc
      }
    )
  }
`;

const DELETE_PEER_CONNECTION = gql`
  mutation DeletePeeringConnection($projectId: ID!, $vpcId: ID!, $id: ID!) {
    deletePeeringConnection(
      data: { projectId: $projectId, vpcId: $vpcId, id: $id }
    )
  }
`;

const ATTACH_SERVICE_TO_VPC = gql`
  mutation AttachServiceToVpc($projectId: ID!, $serviceId: ID!, $vpcId: ID!) {
    attachServiceToVpc(
      data: { projectId: $projectId, serviceId: $serviceId, vpcId: $vpcId }
    )
  }
`;

const DETACH_SERVICE_FROM_VPC = gql`
  mutation DetachServiceFromVpc($projectId: ID!, $serviceId: ID!, $vpcId: ID!) {
    detachServiceFromVpc(
      data: { projectId: $projectId, serviceId: $serviceId, vpcId: $vpcId }
    )
  }
`;

const DELETE_VPC = gql`
  mutation DeleteVpc($projectId: ID!, $vpcId: ID!) {
    deleteVpc(data: { projectId: $projectId, vpcId: $vpcId })
  }
`;

const CREATE_DEMO_SERVICE = gql`
  mutation CreateDemoService(
    $projectId: ID!
    $name: String!
    $type: Type!
    $resourceConfig: ResourceConfig!
    $demoID: Int
    $regionCode: String
  ) {
    createService(
      data: {
        projectId: $projectId
        name: $name
        type: $type
        resourceConfig: $resourceConfig
        regionCode: $regionCode
        demoID: $demoID
      }
    ) {
      service {
        id
        projectId
        name
        type
        status
        created
      }
    }
  }
`;

const CREATE_METRIC_EXPORTER__TYPE = "createMetricExporter";

const CREATE_METRIC_EXPORTER = gql`
  mutation CreateMetricExporter(
    $projectId: ID!
    $name: String!
    $config: MetricExporterConfigInput!
    $regionCode: String!
  ) {
    createMetricExporter(
      data: {
        projectId: $projectId
        name: $name
        config: $config
        regionCode: $regionCode
      }
    ) {
      id
      projectId
      created
      name
      type
      config {
        ... on CloudWatchMetricConfig {
          logGroupName
          logStreamName
          namespace
          awsRegion
          awsRoleArn
        }
        ... on DatadogMetricConfig {
          apiKey
          site
        }
        ... on PrometheusMetricConfig {
          foo
        }
      }
    }
  }
`;

const UPDATE_METRIC_EXPORTER__TYPE = "updateMetricExporter";

const UPDATE_METRIC_EXPORTER = gql`
  mutation UpdateMetricExporter(
    $projectId: ID!
    $name: String!
    $config: MetricExporterConfigInput!
    $exporterId: ID!
  ) {
    updateMetricExporter(
      data: {
        projectId: $projectId
        name: $name
        config: $config
        exporterId: $exporterId
      }
    )
  }
`;

const DELETE_METRIC_EXPORTER__TYPE = "deleteMetricExporter";

const DELETE_METRIC_EXPORTER = gql`
  mutation DeleteMetricExporter($projectId: ID!, $exporterId: ID!) {
    deleteMetricExporter(
      data: { projectId: $projectId, exporterId: $exporterId }
    )
  }
`;

const ATTACH_SERVICE_TO_METRIC_EXPORTER__TYPE = "attachServiceToMetricExporter";
const ATTACH_SERVICE_TO_METRIC_EXPORTER = gql`
  mutation AttachServiceToMetricExporter(
    $projectId: ID!
    $serviceId: ID!
    $exporterId: ID!
  ) {
    attachServiceToMetricExporter(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        exporterId: $exporterId
      }
    )
  }
`;
const DETACH_SERVICE_FROM_METRIC_EXPORTER__TYPE =
  "detachServiceFromMetricExporter";
const DETACH_SERVICE_FROM_METRIC_EXPORTER = gql`
  mutation DetachServiceFromMetricExporter(
    $projectId: ID!
    $serviceId: ID!
    $exporterId: ID!
  ) {
    detachServiceFromMetricExporter(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        exporterId: $exporterId
      }
    )
  }
`;

const CREATE_GENERIC_EXPORTER__TYPE = "createGenericExporter";

const CREATE_GENERIC_EXPORTER = gql`
  mutation CreateGenericExporter(
    $projectId: ID!
    $name: String!
    $region: String!
    $type: GenericExporterType!
    $dataType: GenericExporterDataType!
    $config: GenericExporterConfigInput!
  ) {
    createGenericExporter(
      data: {
        projectId: $projectId
        name: $name
        config: $config
        type: $type
        dataType: $dataType
        region: $region
      }
    ) {
      id
      projectId
      regionCode
      created
      name
      type
    }
  }
`;

const UPDATE_GENERIC_EXPORTER__TYPE = "updateGenericExporter";

const UPDATE_GENERIC_EXPORTER = gql`
  mutation UpdateGenericExporter(
    $projectId: ID!
    $exporterId: String!
    $name: String!
    $config: GenericExporterConfigInput!
  ) {
    updateGenericExporter(
      data: {
        projectId: $projectId
        name: $name
        exporterId: $exporterId
        config: $config
      }
    )
  }
`;

const DELETE_GENERIC_EXPORTER__TYPE = "deleteGenericExporter";

const DELETE_GENERIC_EXPORTER = gql`
  mutation DeleteGenericExporter($projectId: ID!, $exporterId: String!) {
    deleteGenericExporter(
      data: { projectId: $projectId, exporterId: $exporterId }
    )
  }
`;

const ATTACH_SERVICE_TO_GENERIC_EXPORTER__TYPE =
  "attachServiceToGenericExporter";

const ATTACH_SERVICE_TO_GENERIC_EXPORTER = gql`
  mutation AttachServiceToGenericExporter(
    $projectId: ID!
    $serviceId: ID!
    $exporterId: String!
  ) {
    attachServiceToGenericExporter(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        exporterId: $exporterId
      }
    )
  }
`;

const DETACH_SERVICE_FROM_GENERIC_EXPORTER__TYPE =
  "detachServiceFromGenericExporter";

const DETACH_SERVICE_FROM_GENERIC_EXPORTER = gql`
  mutation DetachServiceFromGenericExporter(
    $projectId: ID!
    $serviceId: ID!
    $exporterId: String!
  ) {
    detachServiceFromGenericExporter(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        exporterId: $exporterId
      }
    )
  }
`;

const SET_COMPRESSION_HYPERTABLE = gql`
  mutation SetCompressionHypertable(
    $serviceId: ID!
    $projectId: ID!
    $regionCode: String!
    $config: CompressionConfig!
    $tableSchema: ID!
    $tableName: ID!
  ) {
    setCompressionHypertable(
      data: {
        serviceId: $serviceId
        projectId: $projectId
        regionCode: $regionCode
        config: $config
        tableSchema: $tableSchema
        tableName: $tableName
      }
    )
  }
`;

const SET_COMPRESSION__TYPE = "setCompression";
const SET_COMPRESSION = gql`
  mutation SetCompression(
    $serviceId: ID!
    $projectId: ID!
    $regionCode: String!
    $config: CompressionConfig!
    $schema: ID!
    $tableName: ID
    $contAggName: ID
  ) {
    setCompression(
      data: {
        serviceId: $serviceId
        projectId: $projectId
        regionCode: $regionCode
        config: $config
        schema: $schema
        tableName: $tableName
        contAggName: $contAggName
      }
    )
  }
`;

const ADD_COMPRESSION_POLICY = gql`
  mutation AddCompressionPolicy(
    $serviceId: ID!
    $projectId: ID!
    $regionCode: String!
    $tableSchema: ID!
    $tableName: ID
    $contAggName: ID
    $olderThan: String!
  ) {
    addCompressionPolicy(
      data: {
        serviceId: $serviceId
        projectId: $projectId
        regionCode: $regionCode
        tableSchema: $tableSchema
        tableName: $tableName
        contAggName: $contAggName
        olderThan: $olderThan
      }
    )
  }
`;

const DELETE_COMPRESSION_HYPERTABLE = gql`
  mutation DeleteCompressionPolicy(
    $serviceId: ID!
    $projectId: ID!
    $regionCode: String!
    $tableSchema: ID!
    $tableName: ID!
  ) {
    deleteCompressionPolicy(
      data: {
        serviceId: $serviceId
        projectId: $projectId
        regionCode: $regionCode
        tableSchema: $tableSchema
        tableName: $tableName
      }
    )
  }
`;

const ADD_MFA_METHOD = gql`
  mutation AddMfaMethod($code: String!, $secret: String!) {
    addMfaMethod(data: { authenticator: { secret: $secret, code: $code } }) {
      recoveryCodes
      method {
        ... on AuthenticatorMethod {
          id
        }
      }
    }
  }
`;

const DELETE_MFA_METHOD = gql`
  mutation DeleteMfaMethod($id: ID!, $code: String!) {
    deleteMfaMethod(data: { id: $id, code: $code })
  }
`;

const REGENERATE_MFA_RECOVERY_CODES = gql`
  mutation RegenerateRecoveryCodes($code: String!) {
    regenerateRecoveryCodes(data: { code: $code })
  }
`;

const CREATE_SUPPORT_TICKET__TYPE = "createSupportTicket";
const CREATE_SUPPORT_TICKET = gql`
  mutation CreateSupportTicket(
    $projectId: ID!
    $services: [SupportServiceInfoInput!]
    $subject: String!
    $description: String!
    $email: String!
    $userId: ID!
    $name: String!
    $isProduction: Boolean!
    $impact: String!
  ) {
    createSupportTicket(
      data: {
        projectId: $projectId
        services: $services
        subject: $subject
        description: $description
        email: $email
        userId: $userId
        name: $name
        isProduction: $isProduction
        impact: $impact
      }
    )
  }
`;

const TOGGLE_DATA_TIERING_INPUT = gql`
  mutation ToggleDataTieringInput(
    $serviceId: ID!
    $projectId: ID!
    $enable: Boolean!
  ) {
    toggleDataTiering(
      data: { serviceId: $serviceId, projectId: $projectId, enable: $enable }
    )
  }
`;

const CREATE_PAT_RECORD__TYPE = "createPATRecord";
const CREATE_PAT_RECORD = gql`
  mutation CreatePATRecord($projectId: ID!, $name: String!) {
    createPATRecord(
      createPATRecordInput: { projectId: $projectId, name: $name }
    ) {
      clientCredentials {
        accessKey
        secretKey
      }
      name
      projectId
      created
    }
  }
`;

const DELETE_PAT_RECORD__TYPE = "deletePATRecord";
const DELETE_PAT_RECORD = gql`
  mutation DeletePATRecord($accessKey: String!) {
    deletePATRecord(deletePATRecordInput: { accessKey: $accessKey })
  }
`;

const RENAME_PAT_RECORD__TYPE = "renamePATRecord";
const RENAME_PAT_RECORD = gql`
  mutation RenamePATRecord($accessKey: String!, $newName: String!) {
    renamePATRecord(
      renamePATRecordInput: { accessKey: $accessKey, newName: $newName }
    )
  }
`;

const SWITCHOVER_INSTANCE = gql`
  mutation SwitchoverInstance($projectId: ID!, $serviceId: ID!) {
    switchoverInstance(data: { projectId: $projectId, serviceId: $serviceId })
  }
`;

const TOGGLE_CONNECTION_POOLER = gql`
  mutation ToggleConnectionPooler(
    $serviceId: ID!
    $projectId: ID!
    $enable: Boolean!
  ) {
    toggleConnectionPooler(
      data: { serviceId: $serviceId, projectId: $projectId, enable: $enable }
    )
  }
`;

const CHARGE_INVOICE__TYPE = "chargeInvoice";
const CHARGE_INVOICE = gql`
  mutation ChargeInvoice($invoiceId: ID!) {
    chargeInvoice(invoiceId: $invoiceId)
  }
`;

const TRANSFER_PROJECT_OWNERSHIP__TYPE = "transferProjectOwnership";
const TRANSFER_PROJECT_OWNERSHIP = gql`
  mutation TransferProjectOwnership(
    $projectId: ID!
    $newOwnerId: ID!
    $mfaCode: String!
    $password: String!
  ) {
    transferProjectOwnership(
      data: {
        projectId: $projectId
        newOwnerId: $newOwnerId
        mfaCode: $mfaCode
        password: $password
      }
    )
  }
`;

const NEW_CSV_IMPORT__TYPE = "newCsvImport";
const NEW_CSV_IMPORT = gql`
  mutation NewCsvImport($serviceId: ID!, $projectId: ID!) {
    newCsvImport(data: { projectId: $projectId, serviceId: $serviceId }) {
      id
      state
      url
    }
  }
`;

const PROCESS_CSV_IMPORT__TYPE = "processCsvImport";
const PROCESS_CSV_IMPORT = gql`
  mutation ProcessCsvImport(
    $serviceId: ID!
    $projectId: ID!
    $id: String!
    $schema_name: String!
    $table_name: String!
    $delimiter: String!
    $skip_header: Boolean!
    $columns: [CsvImportColumnInput!]!
    $time_column: String!
  ) {
    processCsvImport(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        id: $id
        schema_name: $schema_name
        table_name: $table_name
        delimiter: $delimiter
        skip_header: $skip_header
        columns: $columns
        time_column: $time_column
      }
    ) {
      id
      state
    }
  }
`;

const NEW_FILE_IMPORT__TYPE = "newFileImport";
const NEW_FILE_IMPORT = gql`
  mutation NewFileImport($serviceId: ID!, $projectId: ID!) {
    newFileImport(data: { projectId: $projectId, serviceId: $serviceId }) {
      id
      state
      url
    }
  }
`;

const PROCESS_FILE_IMPORT__TYPE = "processFileImport";
const PROCESS_FILE_IMPORT = gql`
  mutation ProcessFileImport(
    $serviceId: ID!
    $projectId: ID!
    $id: String!
    $schema_name: String!
    $table_name: String!
    $columns: [CsvImportColumnInput!]!
    $time_column: String!
  ) {
    processFileImport(
      data: {
        parquet: {
          projectId: $projectId
          serviceId: $serviceId
          id: $id
          schema_name: $schema_name
          table_name: $table_name
          columns: $columns
          time_column: $time_column
        }
      }
    ) {
      id
      state
    }
  }
`;

const SET_VIRTUAL_PLAN_FOR_PROJECT__TYPE = "setVirtualPlanForProject";
const SET_VIRTUAL_PLAN_FOR_PROJECT = gql`
  mutation SetVirtualPlanForProject(
    $projectId: ID!
    $virtualPlanKind: VirtualPlanType!
  ) {
    setVirtualPlanForProject(
      data: { projectId: $projectId, virtualPlanKind: $virtualPlanKind }
    )
  }
`;

const SET_HYPERTABLE_CONFIG__TYPE = "";
const SET_HYPERTABLE_CONFIG = gql`
  mutation SetHypertableConf(
    $projectId: ID!
    $serviceId: ID!
    $schema: ID!
    $tableName: ID!
    $chunkSizeIntervalDuration: String!
  ) {
    setHypertableConf(
      data: {
        projectId: $projectId
        serviceId: $serviceId
        schema: $schema
        tableName: $tableName
        chunkSizeIntervalDuration: $chunkSizeIntervalDuration
      }
    )
  }
`;

const CREATE_PROJECT_AI_SECRET__TYPE = "createProjectSecret";
const CREATE_PROJECT_AI_SECRET = gql`
  mutation CreateProjectSecret(
    $projectId: ID!
    $name: String!
    $value: String!
  ) {
    createProjectSecret(
      data: { projectId: $projectId, name: $name, value: $value }
    )
  }
`;

const UPDATE_PROJECT_AI_SECRET = gql`
  mutation UpdateProjectSecret(
    $projectId: ID!
    $name: String!
    $value: String!
  ) {
    updateProjectSecret(
      data: { projectId: $projectId, name: $name, value: $value }
    )
  }
`;

const DELETE_PROJECT_AI_SECRET = gql`
  mutation DeleteProjectSecret($projectId: ID!, $name: String!) {
    deleteProjectSecret(data: { projectId: $projectId, name: $name })
  }
`;

export {
  CHANGE_PASSWORD,
  CHANGE_MAINTENANCE_WINDOW,
  CHANGE_MAINTENANCE_WINDOW__TYPE,
  CREATE_PROJECT,
  CREATE_SERVICE_FORK,
  CREATE_SERVICE,
  CREATE_SERVICE__TYPE,
  SET_REPLICA_COUNT,
  // CREATE_RESOURCE,
  CREATE_DEMO_SERVICE,
  CREATE_SUPPORT_TICKET,
  CREATE_SUPPORT_TICKET__TYPE,
  CREATE_USER,
  CREATE_USER__TYPE,
  CREATE_USER_PERSONA,
  CREATE_USER_PERSONA__TYPE,
  UPDATE_USER_PERSONA,
  UPDATE_USER_PERSONA__TYPE,
  SET_ENVIRONMENT_TAG,
  SET_ENVIRONMENT_TAG__TYPE,
  CREATE_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD__TYPE,
  UPDATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD__TYPE,
  SET_PRIMARY_PAYMENT_METHOD,
  CREATE_BILLING_EMAIL,
  DELETE_BILLING_EMAIL,
  VERIFY_BILLING_EMAIL,
  RESEND_VERIFY_BILLING_EMAIL,
  CANCEL_PENDING_PAYMENT_DELETION,
  JOIN_PROJECT,
  DECLINE_PROJECT_INVITE,
  LEAVE_PROJECT,
  CANCEL_PROJECT_INVITE,
  REVOKE_PROJECT_MEMBER,
  INVITE_PROJECT_MEMBER,
  RESEND_INVITE_PROJECT_MEMBER,
  DELETE_SERVICE,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD__TYPE,
  RENAME_SERVICE,
  RENAME_VPC,
  RENAME_PROJECT,
  RESET_PASSWORD,
  RESET_SERVICE_PASSWORD,
  RESEND_VERIFY_EMAIL,
  RESIZE_INSTANCE,
  SEND_NEW_PASSWORD,
  SEND_SUPPORT_EMAIL,
  SET_ADDRESS_INFO__TYPE,
  SET_ADDRESS_INFO,
  SET_POSTGRES_PARAMETERS,
  RESET_POSTGRES_PARAMETERS,
  TOGGLE_SERVICE,
  TOGGLE_POPSQL_INTEGRATION,
  UPDATE_USER,
  VERIFY_EMAIL,
  CREATE_VPC,
  DELETE_VPC,
  OPEN_PEER_REQUEST,
  DELETE_PEER_CONNECTION,
  ATTACH_SERVICE_TO_VPC,
  DETACH_SERVICE_FROM_VPC,
  UPGRADE_SERVICE_POSTGRES_MAJOR_VERSION,
  CREATE_METRIC_EXPORTER,
  CREATE_METRIC_EXPORTER__TYPE,
  UPDATE_METRIC_EXPORTER,
  UPDATE_METRIC_EXPORTER__TYPE,
  DELETE_METRIC_EXPORTER,
  DELETE_METRIC_EXPORTER__TYPE,
  CREATE_GENERIC_EXPORTER,
  CREATE_GENERIC_EXPORTER__TYPE,
  UPDATE_GENERIC_EXPORTER,
  UPDATE_GENERIC_EXPORTER__TYPE,
  DELETE_GENERIC_EXPORTER,
  DELETE_GENERIC_EXPORTER__TYPE,
  ATTACH_SERVICE_TO_GENERIC_EXPORTER,
  ATTACH_SERVICE_TO_GENERIC_EXPORTER__TYPE,
  DETACH_SERVICE_FROM_GENERIC_EXPORTER,
  DETACH_SERVICE_FROM_GENERIC_EXPORTER__TYPE,
  ATTACH_SERVICE_TO_METRIC_EXPORTER,
  ATTACH_SERVICE_TO_METRIC_EXPORTER__TYPE,
  DETACH_SERVICE_FROM_METRIC_EXPORTER,
  DETACH_SERVICE_FROM_METRIC_EXPORTER__TYPE,
  SET_COMPRESSION_HYPERTABLE,
  SET_COMPRESSION,
  SET_COMPRESSION__TYPE,
  ADD_COMPRESSION_POLICY,
  DELETE_COMPRESSION_HYPERTABLE,
  ADD_MFA_METHOD,
  DELETE_MFA_METHOD,
  REGENERATE_MFA_RECOVERY_CODES,
  TOGGLE_DATA_TIERING_INPUT,
  CREATE_PAT_RECORD,
  CREATE_PAT_RECORD__TYPE,
  DELETE_PAT_RECORD,
  DELETE_PAT_RECORD__TYPE,
  RENAME_PAT_RECORD,
  RENAME_PAT_RECORD__TYPE,
  SWITCHOVER_INSTANCE,
  TOGGLE_CONNECTION_POOLER,
  CHARGE_INVOICE,
  CHARGE_INVOICE__TYPE,
  TRANSFER_PROJECT_OWNERSHIP,
  TRANSFER_PROJECT_OWNERSHIP__TYPE,
  NEW_CSV_IMPORT__TYPE,
  NEW_CSV_IMPORT,
  PROCESS_CSV_IMPORT__TYPE,
  PROCESS_CSV_IMPORT,
  NEW_FILE_IMPORT__TYPE,
  NEW_FILE_IMPORT,
  PROCESS_FILE_IMPORT__TYPE,
  PROCESS_FILE_IMPORT,
  SET_VIRTUAL_PLAN_FOR_PROJECT,
  SET_VIRTUAL_PLAN_FOR_PROJECT__TYPE,
  SET_HYPERTABLE_CONFIG,
  SET_HYPERTABLE_CONFIG__TYPE,
  CREATE_PROJECT_AI_SECRET,
  CREATE_PROJECT_AI_SECRET__TYPE,
  UPDATE_PROJECT_AI_SECRET,
  DELETE_PROJECT_AI_SECRET,
};
