import { autorun, runInAction, reaction, makeAutoObservable } from "mobx";
import { isEmpty, isNil } from "lodash";
import { client } from "api/client";
import projectsStore from "./projectsStore";
import notificationStore from "./notificationStore";
import commonStore from "./commonStore";
import dayjs from "dayjs";
import {
  READY,
  LOADING,
  NOT_INITIATED,
  EXISTS,
  DOES_NOT_EXIST,
  StoreStatus,
  PaymentStatus,
} from "./constants";

import {
  CHARGE_INVOICE,
  CHARGE_INVOICE__TYPE,
  CREATE_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD__TYPE,
  UPDATE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  CANCEL_PENDING_PAYMENT_DELETION,
  SET_PRIMARY_PAYMENT_METHOD,
} from "api/mutations";

import {
  GET_ORB_INVOICES,
  GET_ORB_INVOICES__TYPE,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS__TYPE,
  GET_PENDING_PAYMENT_DELETION,
  GET_PENDING_PAYMENT_DELETION__TYPE,
} from "api/query";
import { ALERTS } from "utils/config";
import {
  Maybe,
  PaymentMethod,
  DeletePaymentMethodMutationVariables,
  SetPrimaryPaymentMethodMutationVariables,
  MutationChargeInvoiceArgs,
  Invoice,
} from "graphql/generated";
import {
  PaymentMethodTrialExpiring,
  PaymentMethodAddition,
} from "components/notificationBanner/notificationBannerMessages";

export class PaymentMethodsStore {
  paymentMethods: PaymentMethod[] = [];
  pendingPaymentDeletion: Maybe<PaymentMethod> = null;
  billingAccountId: Maybe<string> = null;
  storeStatus: StoreStatus = NOT_INITIATED;
  paymentInfoStatus: Maybe<PaymentStatus> = null;
  setupIntent = null;
  invoices: Invoice[] = [];
  oldestInvoiceYear: number = dayjs().year();

  constructor() {
    makeAutoObservable(this);

    autorun(() => {
      if (
        (projectsStore.hasAnyPaymentMethod ||
          (!isNil(projectsStore?.trialDaysLeft) &&
            projectsStore?.trialDaysLeft >= 7)) &&
        [ALERTS.TRIAL_EXPIRING, ALERTS.TRIAL_EXPIRED].includes(
          notificationStore.alert.type,
        )
      ) {
        notificationStore.setAlert({ show: false });
      }
    });
    reaction(
      () => [
        projectsStore.storeStatus,
        projectsStore.currentProject?.billingAccount?.id,
      ],
      async ([status, id]) => {
        try {
          if (status === READY && id) {
            runInAction(() => {
              this.billingAccountId = id || null;
            });
            await this.getPendingPaymentDeletion();
            await this.getPaymentMethods();
            runInAction(() => {
              this.storeStatus = READY;
              this.paymentInfoStatus = this._paymentInfoStatus;
            });
          } else {
            this._setStoreStatus(NOT_INITIATED);
          }
        } catch {
          console.warn("Error updating paymentMethodsStore");
        }
      },
    );
    reaction(
      () => [projectsStore.hasAnyPaymentMethod, projectsStore?.trialDaysLeft],
      ([hasAnyPaymentMethod, trialDaysLeft]) => {
        // trialDaysLeft null checks stop the banner from briefly appearing on refresh,
        // even if it's not supposed to
        if (
          hasAnyPaymentMethod ||
          (!isNil(trialDaysLeft) && trialDaysLeft >= 7)
        ) {
          commonStore.setAppDisabled(false);
          if (
            [ALERTS.TRIAL_EXPIRING, ALERTS.TRIAL_EXPIRED].includes(
              notificationStore.alert.type,
            )
          ) {
            notificationStore.setAlert({
              show: false,
            });
          }
        } else if (!isNil(trialDaysLeft) && trialDaysLeft > 0) {
          commonStore.setAppDisabled(false);
          notificationStore.setAlert({
            show: true,
            type: ALERTS.TRIAL_EXPIRING,
            message: PaymentMethodTrialExpiring,
          });
        } else if (!isNil(trialDaysLeft)) {
          commonStore.setAppDisabled(true);
          notificationStore.setAlert({
            show: true,
            type: ALERTS.TRIAL_EXPIRED,
            message: PaymentMethodAddition,
          });
        }
      },
    );
    autorun(() => {
      runInAction(() => {
        this.paymentMethods =
          projectsStore?.billingAccount?.paymentMethods || [];
      });
    });
  }

  get _paymentInfoStatus() {
    if (this.storeStatus === READY) {
      return isEmpty(this.paymentMethods) ? EXISTS : DOES_NOT_EXIST;
    } else {
      return this.storeStatus;
    }
  }

  _setStoreStatus = (status: StoreStatus) => {
    runInAction(() => (this.storeStatus = status));
  };

  getPaymentMethods = async () => {
    this._setStoreStatus(LOADING);
    try {
      if (!this.billingAccountId) {
        console.warn("Request aborted: Billing account ID is null");
        throw Error;
      }
      const { data } = await client.query({
        variables: { billingAccountId: this.billingAccountId },
        query: GET_PAYMENT_METHODS,
      });

      if (data) {
        runInAction(() => {
          this.paymentMethods = data[GET_PAYMENT_METHODS__TYPE];
        });
      }
    } catch (e) {
      notificationStore.showErrorToaster(
        "There was a problem processing your card.  If this persists, please contact support",
      );
    } finally {
      this._setStoreStatus(this.billingAccountId ? READY : NOT_INITIATED);
    }
  };

  createPaymentMethod = async (isPrimary = false) => {
    try {
      if (!this.billingAccountId) {
        console.warn("Request aborted: Billing account ID is null");
        throw Error;
      }
      const { data, errors } = await client.mutate({
        variables: { billingAccountId: this.billingAccountId, isPrimary },
        mutation: CREATE_PAYMENT_METHOD,
      });
      if (errors) {
        notificationStore.showErrorToaster(
          "There was a problem adding your payment method.  If this persists, please contact support",
        );
        return null;
      } else if (data) {
        return data[CREATE_PAYMENT_METHOD__TYPE];
      }
    } catch (e) {
      notificationStore.showErrorToaster(
        "There was a problem adding your payment method.  If this persists, please contact support",
      );
      return null;
    } finally {
      setTimeout(() => {
        this.getPaymentMethods();
      }, 3000);
    }
  };

  updatePaymentMethod = async ({
    id,
    cardInfo,
    addressInfo,
  }: PaymentMethod) => {
    const { expMonth, expYear } = cardInfo;
    try {
      if (!this.billingAccountId) {
        console.warn("Request aborted: Billing account ID is null");
        throw Error;
      }
      const { data, errors } = await client.mutate({
        mutation: UPDATE_PAYMENT_METHOD,
        variables: {
          billingAccountId: this.billingAccountId,
          paymentMethodId: id,
          addressInfo,
          cardExp: {
            expMonth,
            expYear,
          },
        },
      });
      if (data) {
        notificationStore.showSuccessToaster(
          "Billing address was successfully updated.",
        );
      }
      if (errors) {
        notificationStore.showErrorToaster(
          "There was a problem updating your payment.  If this persists, please contact support",
        );
      }
      return data;
    } catch (e) {
      console.error("System error updatePaymentMethod: ", e);
    } finally {
      await this.getPaymentMethods();
    }
  };

  deletePaymentMethod = async ({ id }: PaymentMethod) => {
    try {
      if (!this.billingAccountId) {
        console.warn("Request aborted: Billing account ID is null");
        throw Error;
      }
      const variables = {
        billingAccountId: this.billingAccountId,
        paymentMethodId: id,
      };
      const { data, errors } = await client.mutate({
        variables,
        mutation: DELETE_PAYMENT_METHOD,
      });
      if (errors && !data) {
        notificationStore.showErrorToaster(
          "There was a problem deleting your payment.  If this persists, please contact support",
        );
      }
      return data ? true : false;
    } catch (e) {
      notificationStore.showErrorToaster(
        "There was a problem deleting your payment.  If this persists, please contact support",
      );
      return null;
    } finally {
      await this.getPaymentMethods();
      await this.getPendingPaymentDeletion();
    }
  };

  getPendingPaymentDeletion = async () => {
    this._setStoreStatus(LOADING);
    try {
      if (!this.billingAccountId) {
        console.warn("Request aborted: Billing account ID is null");
      }
      const { data } = await client.query({
        variables: { billingAccountId: this.billingAccountId },
        query: GET_PENDING_PAYMENT_DELETION,
      });

      if (data) {
        runInAction(() => {
          this.pendingPaymentDeletion =
            data?.[GET_PENDING_PAYMENT_DELETION__TYPE];
        });
      }
    } catch (e) {
      console.error("getPendingPaymentDeletion: Network Error ", e);
    } finally {
      this._setStoreStatus(this.billingAccountId ? READY : NOT_INITIATED);
    }
  };

  cancelPendingPaymentDeletionMethod = async ({
    paymentMethodId,
  }: DeletePaymentMethodMutationVariables) => {
    try {
      if (!this.billingAccountId) {
        console.warn("Request aborted: Billing account ID is null");
        throw Error;
      }
      const { data } = await client.mutate({
        variables: {
          billingAccountId: this.billingAccountId,
          paymentMethodId,
        },
        mutation: CANCEL_PENDING_PAYMENT_DELETION,
      });
      return data ? true : false;
    } catch (e) {
      notificationStore.showErrorToaster(
        "There was a problem with your cancellation.  If this persists, please contact support",
      );
      return false;
    } finally {
      await this.getPendingPaymentDeletion();
    }
  };

  setPrimaryPaymentMethod = async (
    id: SetPrimaryPaymentMethodMutationVariables,
  ) => {
    try {
      if (!this.billingAccountId) {
        console.warn("Request aborted: Billing account ID is null");
        throw Error;
      }
      const variables = {
        billingAccountId: this.billingAccountId,
        paymentMethodId: id,
      };
      await client.mutate({
        variables,
        mutation: SET_PRIMARY_PAYMENT_METHOD,
      });
      notificationStore.showSuccessToaster(
        "Your payment method was successfully updated.",
      );
    } catch (e) {
      notificationStore.showErrorToaster(
        "There was a problem setting your primary payment method.  If this persists, please contact support",
      );
    } finally {
      await this.getPaymentMethods();
    }
  };

  setPaymentMethodSetupIntent = (setupIntent: any) => {
    runInAction(() => {
      this.setupIntent = setupIntent;
    });
  };

  getInvoices = async (year: number | null = null) => {
    try {
      if (!this.billingAccountId) {
        console.warn("Request aborted: Billing account ID is null");
        throw Error;
      }
      const { data } = await client.query({
        variables: {
          billingAccountId: this.billingAccountId,
          year,
        },
        query: GET_ORB_INVOICES,
      });
      if (data) {
        const { invoices = [] } = data[GET_ORB_INVOICES__TYPE];
        runInAction(() => {
          this.invoices = invoices;
          if (projectsStore.currentProject?.created) {
            this.oldestInvoiceYear = dayjs(projectsStore.currentProject.created)
              .add(1, "month")
              .year();
          }
        });
      }
    } catch (e) {
      console.log("getInvoices: Network error");
    }
  };

  chargeInvoice = async ({ invoiceId }: MutationChargeInvoiceArgs) => {
    try {
      const { data, errors } = await client.mutate({
        variables: {
          invoiceId: invoiceId,
        },
        mutation: CHARGE_INVOICE,
      });
      if (data) {
        notificationStore.showSuccessToaster("Payment processed successfully.");
        return data[CHARGE_INVOICE__TYPE];
      }
      if (errors) {
        notificationStore.showErrorToaster("Error processing payment.");
      }
    } catch (e) {
      console.error("chargeInvoice: Network error", e);
    }
  };
}

const paymentMethodsStore = new PaymentMethodsStore();

export default paymentMethodsStore;
