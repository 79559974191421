import { Box, Flex, Text } from "@chakra-ui/react";
import Hover from "../tooltip/Hover/Hover";
import { GradientText } from "../gradients/GradientText";

export type ModeOptionProps = {
  id: string;
  label: string;
  isChecked: boolean;
  isTimescale: boolean;
};

export type ModeSwitcherProps = {
  options: [ModeOptionProps, ModeOptionProps];
  onClick: () => void;
  isMinified: boolean;
};

export const ModeSwitcher = ({
  options,
  onClick,
  isMinified,
}: ModeSwitcherProps) => {
  return (
    <Flex
      direction={isMinified ? "column" : "row"}
      borderRadius="8px"
      borderWidth="1px"
      borderColor="grayscale.200"
      gap="2px"
    >
      {options.map(({ id, label, isChecked, isTimescale }) => (
        <Hover
          key={id}
          theme="menu"
          placement="right"
          content={label}
          disabled={!isMinified}
        >
          <Flex
            justifyContent="center"
            py={isMinified ? "12px" : "6px"}
            px={isMinified ? "13px" : "9px"}
            borderRadius="8px"
            m={isChecked ? "-1px" : "0"}
            borderWidth="1px"
            cursor="pointer"
            flex="1 1 0px"
            borderColor={isChecked ? "grayscale.200" : "grayscale.0"}
            bgColor={isChecked ? "grayscale.100" : "grayscale.0"}
            _hover={{
              "&>p": {
                bg: isTimescale ? "timescaleGradient.90" : "popsqlGradient.90",
                backgroundClip: "text",
                textFillColor: "transparent",
              },
              "&>div>div": {
                bg: isTimescale ? "timescaleGradient.90" : "popsqlGradient.90",
              },
            }}
            data-cy="mode-switcher"
            onClick={() => onClick()}
          >
            {isMinified ? (
              <Box
                bg={isTimescale ? "timescaleGradient.90" : "popsqlGradient.90"}
                p="1px"
                borderRadius="2px"
              >
                <Flex
                  w="6px"
                  h="6px"
                  borderRadius="1px"
                  bg={
                    isChecked && isTimescale
                      ? "timescaleGradient.90"
                      : isChecked
                        ? "popsqlGradient.90"
                        : "grayscale.0"
                  }
                />
              </Box>
            ) : isChecked ? (
              <GradientText
                gradient={
                  isTimescale ? "timescaleGradient.90" : "popsqlGradient.90"
                }
                textStyle="base"
              >
                {label}
              </GradientText>
            ) : (
              <Text color="grayscale.700">{label}</Text>
            )}
          </Flex>
        </Hover>
      ))}
    </Flex>
  );
};
