import {
  Box,
  Fade,
  Flex,
  Slide,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useDisclosure,
} from "@chakra-ui/react";
import { QueryWidget } from "@timescale/popsql-query-widget";
import IconButton from "components/button/IconButton";
import TagInfo from "components/tags/TagInfo/TagInfo";
import { observer } from "mobx-react";
import { useState } from "react";
import { useStores } from "stores";
import { useCurrentServiceQueryEngine } from "contexts/queryEngine";

export const SlideOutSQLEditor = observer(() => {
  const { isOpen, onToggle } = useDisclosure();
  const { getExecuteQueryData } = useCurrentServiceQueryEngine();
  const { serviceStore } = useStores();
  const { service } = serviceStore;
  const [query, setQuery] = useState("");
  const [opacity, setOpacity] = useState(0.8);

  onkeydown = (event) => {
    if (event.key === "~" && service?.id) {
      onToggle();
    }

    if (event.key === "Escape" && isOpen) {
      onToggle();
    }
  };

  return (
    <>
      <Fade in={isOpen} style={{ zIndex: 10 }}>
        <Box
          background="rgba(0,0,0,.95)"
          opacity={opacity}
          w="100vw"
          h="100vh"
          display="block"
          position="absolute"
          top="0"
          left="0"
          pointerEvents={!isOpen ? "none" : undefined}
          data-cy="quakeSQL"
        />
      </Fade>
      <Slide
        direction="top"
        in={isOpen}
        style={{ zIndex: 10, opacity: opacity }}
      >
        <Box
          p="20px 40px 40px 40px"
          color="white"
          bg="grayscale.900"
          shadow="md"
        >
          <Flex alignItems="center" gap="8px" w="100%">
            <Box>{service?.name}</Box>
            <Box
              bg="grayscale.200"
              borderRadius="8px"
              textStyle="caption"
              color="grayscale.700"
              width="fit-content"
              px="8px"
            >
              ID: {service?.id}
            </Box>
            <Box w="150px" ml="auto">
              <Slider
                id="slider"
                value={opacity * 100}
                min={10}
                max={100}
                isDisabled={false}
                onChange={(val) => setOpacity(val / 100)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </Box>
            <Box>
              <TagInfo label="Internal Beta" />
            </Box>
            <Box>
              <IconButton
                iconName="status/X/Circle"
                label="Delete"
                onClick={onToggle}
                size="lg"
                variant="primaryIcon"
              />
            </Box>
          </Flex>
          <QueryWidget
            className="text-black"
            closeSession
            id="explorer-query-widget"
            getExecuteQueryData={getExecuteQueryData}
            onQueryChange={setQuery}
            query={query}
            resizeHandles="results"
            sessionKey="slideOutSQLEditor"
            toolbarItemAlignment="start"
            runSelection
          />
        </Box>
      </Slide>
    </>
  );
});
