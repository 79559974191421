import { useHistory } from "react-router-dom";
import { useStores } from "stores";
import {
  Flex,
  MenuButton,
  MenuList,
  Menu,
  Text,
  forwardRef,
  Box,
  StyleProps,
  MenuDivider,
  MenuItem as ChakraMenuItem,
  useBreakpointValue,
  Popover,
  useDisclosure,
  PopoverAnchor,
} from "@chakra-ui/react";
import Icon, { Icons } from "components/icon/Icon";
import { Project } from "graphql/generated";
import { observer } from "mobx-react";
import ReadOnlyInput from "components/input/ReadOnlyInput";
import { VerticalNavMenuItem } from "components/navigation/internal/VerticalNavMenuItem";
import { Statsig } from "statsig-react";
import { EXP_TIERING } from "../../utils/featuresGates";
import { capitalize } from "lodash";
import { MenuItem, SmallScreenMenu } from "./menuComponents";
import Dropdown from "components/dropdown/Dropdown";
import { useContext, useEffect, useState } from "react";
import {
  GuidedTourIntro,
  GuidedTourPopoverContent,
  GuidedTourProjectSection,
} from "./guidedTour/LeftNavGuidedTour";
import { GuidedTourContext } from "./guidedTour/GuidedTourProvider";
import { LeftNavContext } from "./NavigationProvider";
import { TrialDaysLeftLiteral } from "components/priceBox/shared";
import { EXP_CUSTOM_ONBOARDING } from "utils/featuresExperiments";

const ProjectSettingsButton = forwardRef(
  ({ isActive, isMinified, currentProject, showProjectPlan, ...rest }, ref) => (
    <Flex ref={ref}>
      <VerticalNavMenuItem
        isActive={isActive}
        item={{
          id: "currentProject",
          label: showProjectPlan ? currentProject.name : "Project",
          labelText: showProjectPlan ? (
            <Text
              textStyle="caption"
              color={isActive ? "grayscale.900" : "grayscale.600"}
            >
              {capitalize(currentProject.entitlementSpec?.plan_type) + " plan"}
            </Text>
          ) : (
            <Text
              textStyle="caption"
              color={isActive ? "grayscale.900" : "grayscale.700"}
            >
              {currentProject.name}
            </Text>
          ),
          icon: isMinified ? "elements/Building" : <></>,
          hoverText: "Project",
        }}
        isChevron={true}
        {...rest}
      />
    </Flex>
  ),
);

export const ProjectMenu = observer(() => {
  const { projectsStore, userStore } = useStores();
  const {
    currentProject,
    switchProjects,
    allProjects,
    isFreeTrial,
    trialDaysLeft,
  } = projectsStore;
  const { isLeftNavMinified, setIsMenuOpen } = useContext(LeftNavContext);

  const history = useHistory();
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const [showSmallScreenMenu, setShowSmallScreenMenu] = useState(false);

  const isPlanFeatureAvailable = Statsig.checkGate(EXP_TIERING.GATE);

  const menuItems = [
    {
      id: "projectSettings",
      label: "Project settings",
      iconName: "actions/Settings" as Icons,
      onClick: () => {
        history.push("/dashboard/settings");
        setIsMenuOpen(false);
      },
    },
    {
      id: "billing",
      label: "Billing",
      iconName: "elements/Card" as Icons,
      onClick: () => {
        history.push("/dashboard/billing");
        setIsMenuOpen(false);
      },
    },
    {
      id: "users",
      label: "Users",
      iconName: "elements/User" as Icons,
      onClick: () => {
        history.push("/dashboard/members");
        setIsMenuOpen(false);
      },
    },
  ];

  const switchProject = (projectId: string) => {
    switchProjects(projectId);
    userStore.mergeUiState({
      selectedProjectId: projectId,
    });
    history.push("/dashboard/services");
  };

  const {
    onOpen: onPopoverOpen,
    onClose: onPopoverClose,
    isOpen: isPopoverOpen,
  } = useDisclosure();

  const { currentTourStep, goStepBack, skipTour } =
    useContext(GuidedTourContext);

  useEffect(
    () =>
      currentTourStep === 0 || currentTourStep === 2
        ? onPopoverOpen()
        : onPopoverClose(),
    [currentTourStep, onPopoverOpen, onPopoverClose],
  );

  const inOnboardingExperiment = Statsig.getExperiment(
    EXP_CUSTOM_ONBOARDING.ID,
  ).getValue(EXP_CUSTOM_ONBOARDING.PARAMS.SHOW_FEATURE);
  const showGuidedTour = inOnboardingExperiment ? false : true;

  return isSmallScreen ? (
    <>
      <ProjectSettingsButton
        currentProject={currentProject}
        showProjectPlan={isPlanFeatureAvailable}
        onClick={() => setShowSmallScreenMenu(!showSmallScreenMenu)}
      />
      {showSmallScreenMenu && (
        <SmallScreenMenu
          title="Project"
          onTitleClick={() => setShowSmallScreenMenu(false)}
        >
          <>
            <Box px="16px">
              <Dropdown
                options={allProjects.map(({ name, id }: Project) => ({
                  id,
                  label: name,
                }))}
                defaultValue={{
                  id: currentProject.id,
                  label: currentProject.name,
                }}
                onChange={(val) => {
                  switchProject(val.id);
                  setIsMenuOpen(false);
                }}
              />
              <ProjectID projectId={currentProject.id} />
            </Box>

            {menuItems.map((item, i) => (
              <MenuItem key={i} {...item} />
            ))}
          </>
        </SmallScreenMenu>
      )}
    </>
  ) : (
    <Menu
      id="currentProject"
      placement="right-start"
      gutter={16}
      autoSelect={false}
    >
      {({ isOpen, onClose }) => (
        <>
          <Popover
            isOpen={showGuidedTour && isPopoverOpen}
            onOpen={onPopoverOpen}
            placement="right"
            gutter={20}
            arrowSize={12}
          >
            <PopoverAnchor>
              <MenuButton
                as={ProjectSettingsButton}
                isActive={isOpen}
                isMinified={isLeftNavMinified}
                currentProject={currentProject}
                showProjectPlan={isPlanFeatureAvailable}
              />
            </PopoverAnchor>
            {isPopoverOpen && (
              <GuidedTourPopoverContent
                confirmText={
                  currentTourStep === 0 ? "See what's new" : undefined
                }
                cancelText={currentTourStep === 0 ? "Skip the tour" : undefined}
                onCancel={() => {
                  currentTourStep === 0 && skipTour();
                  currentTourStep === 2 && goStepBack();
                }}
              >
                {currentTourStep === 0 ? <GuidedTourIntro /> : <></>}
                {currentTourStep === 2 ? <GuidedTourProjectSection /> : <></>}
              </GuidedTourPopoverContent>
            )}
          </Popover>
          <MenuList>
            <Menu
              id="projectList"
              placement="right-start"
              gutter={16}
              autoSelect={false}
            >
              {({ isOpen: isProjectListOpen }) => (
                <>
                  <MenuButton
                    p="8px"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="4px"
                    bgColor={
                      isProjectListOpen ? "grayscale.100" : "grayscale.0"
                    }
                    borderRadius="8px 8px 0 0"
                    width="fill-available"
                    data-cy="project-switcher"
                  >
                    <Text textStyle="label" textAlign="left">
                      {"Current project".toUpperCase()}
                    </Text>
                    <Flex alignItems="center" justifyContent="space-between">
                      <Text
                        textStyle="baseBold"
                        color={
                          isProjectListOpen ? "grayscale.900" : "grayscale.700"
                        }
                      >
                        {currentProject?.name}
                      </Text>
                      {allProjects.length > 1 ? (
                        <Icon
                          name="actions/Chevron/Right"
                          fill={
                            isProjectListOpen
                              ? "grayscale.900"
                              : "grayscale.700"
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </Flex>
                    {isPlanFeatureAvailable && (
                      <Text
                        textStyle="caption"
                        color="grayscale.600"
                        textAlign="start"
                      >
                        {capitalize(currentProject.entitlementSpec?.plan_type) +
                          " plan"}
                      </Text>
                    )}
                    {isFreeTrial && (
                      <Text
                        textStyle="caption"
                        color="primary.900"
                        textAlign="start"
                      >
                        <TrialDaysLeftLiteral trialDaysLeft={trialDaysLeft} />
                      </Text>
                    )}
                  </MenuButton>

                  <MenuList>
                    {allProjects.map((project: Project) => {
                      const isCurrentProject = project.id === currentProject.id;
                      return (
                        <ChakraMenuItem
                          flexDirection="column"
                          textStyle={isCurrentProject ? "baseBold" : "base"}
                          color={
                            isCurrentProject ? "grayscale.900" : "grayscale.700"
                          }
                          px="8px"
                          alignItems="baseline"
                          gap="2px"
                          onClick={() => {
                            switchProject(project.id);
                            onClose();
                          }}
                          data-cy={`project-switcher-${project.name}`}
                          key={project.id}
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            width="fill-available"
                          >
                            {project.name}
                            {isCurrentProject ? (
                              <Icon
                                name="status/Check/Check"
                                ml="auto"
                                fill="grayscale.900"
                              />
                            ) : (
                              <></>
                            )}
                          </Flex>
                          <Text
                            textStyle="caption"
                            color={
                              isCurrentProject
                                ? "grayscale.700"
                                : "grayscale.500"
                            }
                          >
                            ID: {project.id}
                          </Text>
                        </ChakraMenuItem>
                      );
                    })}
                  </MenuList>
                </>
              )}
            </Menu>

            <MenuDivider />

            <ProjectID projectId={currentProject.id} px="8px" />

            {menuItems.map((item) => (
              <MenuItem
                key={item.id}
                data-cy={`navigation-menu-${item.id}`}
                {...item}
              />
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
});

const ProjectID = ({
  projectId,
  ...customStyles
}: StyleProps & { projectId: string }) => (
  <Flex direction="column" gap="4px" py="8px" {...customStyles}>
    Project ID
    <ReadOnlyInput value={projectId} />
  </Flex>
);
