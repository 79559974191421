import { RegionCode, ServiceType } from "types";
import * as sampleData from "types/sampleData";
const DEV_STRIPE_KEYS = "pk_test_9apl7R6j03VV0g4afCk4Wko900ktLCOSaq";
const PROD_STRIPE_KEYS = "pk_live_9Yz7JNlZt2VbmZOI0ClCN2cs00IV3lvIiq";

const DEV_GOOGLE_RECAPTCHA = "6LfKVrgUAAAAAIQlRjyN7GRttd4UHQJV4FAqT-3U";
const PROD_GOOGLE_RECAPTCHA = "6LcpTbcUAAAAAMlY56e-lT0HsmIP5SNGKcy3nR8l";
export const LIVE_TEST_SIGNUP_PROD_GOOGLE_RECAPTCHA =
  "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

// For Google SSO button
const DEV_GOOGLE_CLIENT_ID =
  "204228388555-g84uhi56ru29b9ni727rjckc5fck8h8g.apps.googleusercontent.com";
const PROD_GOOGLE_CLIENT_ID =
  "204228388555-14k5on76k1l13lcnc9pin24elbm9j65q.apps.googleusercontent.com";

const DEV_WEB_ENDPOINT = "https://dev-responses.timescale.com"; // `web-endpoint` is a proxy for a lot of requests
const PROD_WEB_ENDPOINT = "https://responses.timescale.com";

const DEV_HUBSPOT = `${DEV_WEB_ENDPOINT}/v2/`;
const PROD_HUBSPOT = `${PROD_WEB_ENDPOINT}/v2/`; //If this is changed, the CSP needs to be changed to match at server/csp_template.yml

const DEV_HUBSPOT_FORM_ID = "bf7be759-c474-403b-8769-945d0cd11020";
const PROD_HUBSPOT_FORM_ID = "e9f0edd7-cc50-42ac-b971-22e14f7c406e";

const DEV_HEAP = "3053890791";
const PROD_HEAP = "1653518580";

const createTallyUrl = (defaultId: string, id?: string) =>
  `https://tally.so/embed/${
    id || defaultId
  }?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&base_url=${
    window.location.host //for tally redirect back to the console
  }&email=`;

//REACT_APP vars so that we can make point to the newest form with CI deployment config
const PROD_TALLY_WELCOME = createTallyUrl(
  "n0BxVP",
  process.env.REACT_APP_PROD_TALLY_WELCOME_ID,
);
const DEV_TALLY_WELCOME = createTallyUrl(
  "3l62KX",
  process.env.REACT_APP_DEV_TALLY_WELCOME_ID,
);
const LOCAL_TALLY_WELCOME = createTallyUrl(
  "3X44rO",
  process.env.REACT_APP_LOCAL_TALLY_WELCOME_ID,
);

const DEV_TALLY_EXIT_SURVEY = createTallyUrl("3qGAQ8");
const PROD_TALLY_EXIT_SURVEY = createTallyUrl("wQRODk");

export const ALGOLIA_ID = "FZW5UHSSH8";
export const ALGOLIA_READ_KEY = "208c061facd1acf573b1bb575eca48ce";
const DEV_ALGOLIA_INDEX = "docs_console_dev";
const PROD_ALGOLIA_INDEX = "docs_console_prod";

// Timescale bounces to our FusionAuth server to package a token and then redirects back to PopSQL.
// You'll need to append `projectId` to the end of the redirect URL to ensure the correct project is loaded.
const POPSQL_CLIENTID_DEV = "65183398-ece9-40c4-84e7-84974c51255b";
const POPSQL_CLIENTID_PROD = "df218f30-0913-49d3-b78c-15ccb31641a6";
const POPSQL_APP_ORIGIN_LOCAL = "https://app.local.popsql.team";
const POPSQL_APP_ORIGIN_DEV = "https://app.popsql.dev";
const POPSQL_APP_ORIGIN_PROD = "https://app.popsql.com";
const POPSQL_REDIRECT_LOCAL =
  "https://local.popsql.team/users/auth/timescale_console/callback";
const POPSQL_REDIRECT_DEV =
  "https://popsql.dev/users/auth/timescale_console/callback";
const POPSQL_REDIRECT_PROD =
  "https://popsql.com/users/auth/timescale_console/callback";

const TIMESCALE_AUTH_FOR_POPSQL_LOCAL = `https://api.dev.metronome-cloud.com/api/idp/external/popsql/login?client_id=${POPSQL_CLIENTID_DEV}&response_type=code&redirect_uri=${POPSQL_REDIRECT_LOCAL}&project=`;
const TIMESCALE_AUTH_FOR_POPSQL_DEV = `https://api.dev.metronome-cloud.com/api/idp/external/popsql/login?client_id=${POPSQL_CLIENTID_DEV}&response_type=code&redirect_uri=${POPSQL_REDIRECT_DEV}&project=`;
const TIMESCALE_AUTH_FOR_POPSQL_PROD = `https://console.cloud.timescale.com/api/idp/external/popsql/login?client_id=${POPSQL_CLIENTID_PROD}&response_type=code&redirect_uri=${POPSQL_REDIRECT_PROD}&project=`;

export const POPSQL_WINDOW_TARGET = "popsql_window";

export const MAINTENANCE_WINDOW_DURATION = 2; //hours

export const USERPILOT_ACCOUNT_ID = "NX-271ca217";

//Note: These must be kept in sync with external env variable names
export const PROD = "production";
export const DEV = "development";

export const denyUrls = [
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /chrome-extension/,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
];

const SENTRY_CONFIG =
  "https://6c2c1c92b24f43c8a4adeaf8ce852856@o417395.ingest.sentry.io/5317137";

// StatSig original url is "https://api.statsig.com/v1" and this is our proxy.
export const STAT_SIG_API_URL =
  process.env.REACT_APP_STATSIG_API_URL ||
  "https://statsig.sre.timescale.com/v1";
export const STAT_SIG_CLIENT_KEY =
  "client-JWxl64CuAw9dnpymDndqVdkerj3l92J6LE2E5fFVTaH";

export const ENVS = {
  production: {
    NAME: PROD,
    GOOGLE_RECAPTCHA: PROD_GOOGLE_RECAPTCHA,
    GOOGLE_CLIENT_ID: PROD_GOOGLE_CLIENT_ID,
    WEB_ENDPOINT: PROD_WEB_ENDPOINT,
    HUBSPOT: PROD_HUBSPOT,
    HUBSPOT_FORM_ID: PROD_HUBSPOT_FORM_ID,
    STRIPE_KEY: PROD_STRIPE_KEYS,
    SENTRY: SENTRY_CONFIG,
    HEAP: PROD_HEAP,
    TALLY_WELCOME: PROD_TALLY_WELCOME,
    TALLY_EXIT_SURVEY: PROD_TALLY_EXIT_SURVEY,
    ALGOLIA_INDEX: PROD_ALGOLIA_INDEX,
    POPSQL_APP_ORIGIN: POPSQL_APP_ORIGIN_PROD,
    POPSQL_REDIRECT: TIMESCALE_AUTH_FOR_POPSQL_PROD,
    FALLBACK_REGION: RegionCode.US_EAST_2,
  },
  development: {
    NAME: DEV,
    GOOGLE_RECAPTCHA: DEV_GOOGLE_RECAPTCHA,
    GOOGLE_CLIENT_ID: DEV_GOOGLE_CLIENT_ID,
    WEB_ENDPOINT: DEV_WEB_ENDPOINT,
    HUBSPOT: DEV_HUBSPOT,
    HUBSPOT_FORM_ID: DEV_HUBSPOT_FORM_ID,
    STRIPE_KEY: DEV_STRIPE_KEYS,
    SENTRY: SENTRY_CONFIG,
    HEAP: DEV_HEAP,
    TALLY_WELCOME: DEV_TALLY_WELCOME,
    TALLY_EXIT_SURVEY: DEV_TALLY_EXIT_SURVEY,
    ALGOLIA_INDEX: DEV_ALGOLIA_INDEX,
    POPSQL_APP_ORIGIN: POPSQL_APP_ORIGIN_DEV,
    POPSQL_REDIRECT: TIMESCALE_AUTH_FOR_POPSQL_DEV,
    FALLBACK_REGION: RegionCode.US_EAST_1,
  },
  local: {
    NAME: DEV,
    GOOGLE_RECAPTCHA: DEV_GOOGLE_RECAPTCHA,
    GOOGLE_CLIENT_ID: DEV_GOOGLE_CLIENT_ID,
    WEB_ENDPOINT: DEV_WEB_ENDPOINT,
    HUBSPOT: DEV_HUBSPOT,
    HUBSPOT_FORM_ID: DEV_HUBSPOT_FORM_ID,
    STRIPE_KEY: DEV_STRIPE_KEYS,
    SENTRY: SENTRY_CONFIG,
    HEAP: DEV_HEAP,
    TALLY_WELCOME: LOCAL_TALLY_WELCOME,
    TALLY_EXIT_SURVEY: DEV_TALLY_EXIT_SURVEY,
    ALGOLIA_INDEX: DEV_ALGOLIA_INDEX,
    POPSQL_APP_ORIGIN: process.env.REACT_APP_LOCAL_POPSQL
      ? POPSQL_APP_ORIGIN_LOCAL
      : POPSQL_APP_ORIGIN_DEV,
    POPSQL_REDIRECT: process.env.REACT_APP_LOCAL_POPSQL
      ? TIMESCALE_AUTH_FOR_POPSQL_LOCAL
      : TIMESCALE_AUTH_FOR_POPSQL_DEV,
    FALLBACK_REGION: RegionCode.US_EAST_1,
  },
};

export const prHostRegex = /^savannah-tiger-cloud-pr-\d+\.onrender\.com$/;

export const API_ENV = (() => {
  const host = process.env.REACT_APP_API_HOSTNAME;
  switch (host) {
    case "https://console.forge.timescale.com":
    case "https://console.cloud.timescale.com":
      return ENVS["production"];
    case "https://api.dev.metronome-cloud.com":
      return ENVS["development"];
    default:
      // PR instances should use development config
      // e.g. https://savannah-tiger-cloud-pr-4480.onrender.com
      if (host && prHostRegex.test(host)) return ENVS["development"];
      return ENVS["local"];
  }
})();

export const IDP_REQUIRED_EMAIL =
  /identity provider must be used for this email domain/i;

export const ALERTS = {
  OFFLINE: "Offline",
  UNSTABLE: "Unstable",
  TRIAL_EXPIRING: "Trial expiring",
  TRIAL_EXPIRED: "Trial expired",
  VPC_EXPIRED: "VPC expired",
  PAYMENT_DELETION_SCHEDULED: "Payment method deletion scheduled",
};

//needs to match chakra ui Alert status props
export const ALERT_TYPE = {
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};

export const TOGGLE_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const CONNECTION = {
  ONLINE: "online",
  OFFLINE: "offline",
};

export const PAGE = {
  IDLE: "idle",
  ACTIVE: "active",
};

//Note: this const is moved to mapStatus.ts for uiv2.0
export const VPC_STATUS = {
  ERROR: "ERROR",
  CREATING: "CREATING",
  CREATED: "CREATED",
  DELETING: "DELETING",
  DELETED: "DELETED",
  UNKNOWN: "UNKNOWN",
};

//Note: this const is moved to mapStatus.ts for uiv2.0
export const VPC_PEER_STATUS = {
  INVALID: "INVALID",
  PENDING: "PENDING",
  TIMEOUT: "TIMEOUT",
  APPROVED: "APPROVED",
  ACTIVE: "ACTIVE",
  DISABLED: "DISABLED",
  DELETING: "DELETING",
  UNKNOWN: "UNKNOWN",
};

export const STATUS = {
  //these values mirror graphQL enum Service.status
  QUEUED: "QUEUED",
  DELETING: "DELETING",
  CONFIGURING: "CONFIGURING",
  READY: "READY",
  DELETED: "DELETED",
  UNSTABLE: "UNSTABLE",
  PAUSING: "PAUSING",
  RESUMING: "RESUMING",
  PAUSED: "PAUSED",
  READ_ONLY: "READ_ONLY",
  UPGRADING: "UPGRADING",
  OPTIMIZING: "OPTIMIZING",
} as const;

export const SIMPLE_STATUS = {
  READY: "READY",
  IN_PROGRESS: "IN_PROGRESS",
  ERROR: "ERROR",
};

export const ACTION = {
  ACCEPT: "ACCEPT",
  DECLINE: "DECLINE",
} as const;

export const IN_PROGRESS_STATUSES = [
  STATUS.QUEUED,
  STATUS.PAUSING,
  STATUS.RESUMING,
  STATUS.UPGRADING,
];

export const DANGER_STATUSES = [STATUS.READ_ONLY, STATUS.UNSTABLE];

export const PAUSE_STATUSES = [STATUS.PAUSED, STATUS.PAUSING];

export const REPLICA_ROLES = {
  PRIMARY: "primary",
  REPLICA: "replica",
};

export const NOTES = {
  DEMO: "demo",
  SERVICE: "service",
  VPC: "vpc",
  FORK: "fork",
};

export const RESOURCE_EVENT_TYPES = {
  IN_PROGRESS: "in_progress",
  CPU_COMPLETE: "cpu_complete",
  STORAGE_COMPLETE: "storage_complete",
  CPU_AND_STORAGE_COMPLETE: "cpu_and_storage_complete",
};

export const FORM_SUBSCRIPTION_IDS = {
  newsletter: 7930475,
  forge: 9175664,
};

export const INVOICE_STATUS = {
  PAID: "paid",
  SENT: "sent",
};

export const TS_DEFAULT_USER = "tsdbadmin";
export const TS_DEFAULT_DB = "tsdb";
export const TS_BLANK_PASSWORD = "<TIMESCALE_DB_PASSWORD>";

const environments = { PROD, DEV };

export const SampleDataServiceConfig = {
  regionCode: RegionCode.US_EAST_1,
  type: ServiceType.Timescaledb,
  enableConnectionPooler: false,
  resourceConfig: {
    milliCPU: 500,
    memoryGB: 2,
    replicaCount: undefined,
    storageGB: 50,
  },
  vpcId: undefined,
};

export const SampleDataConfig: sampleData.Config = {
  IoT: {
    name: "IoT",
    logo: "🛜",
    description: "Readings from a fleet of truck's sensor data.",
    serviceName: "IoT sample data",
    tableName: "ts_demo.readings",
    tag: "11.18 GiB",
    disabled: false,
    demoId: 71,
    totalRecords: "42.5 million",
    recordDescription: "sensor data from trucks",
    databasePeak: {
      definition: [
        {
          accessorKey: "key",
          id: "key",
          header: "Column names",
          size: 200,
          enableSorting: false,
        },
        {
          accessorKey: "time",
          id: "time",
          header: "time",
          enableSorting: false,
        },
        {
          accessorKey: "tags_id",
          id: "tags_id",
          header: "tags_id",
          enableSorting: false,
        },
        {
          accessorKey: "latitude",
          id: "latitude",
          header: "latitude",
          enableSorting: false,
        },
        {
          accessorKey: "longitude",
          id: "longitude",
          header: "longitude",
          enableSorting: false,
        },
        {
          accessorKey: "velocity",
          id: "velocity",
          header: "velocity",
          enableSorting: false,
        },
        {
          accessorKey: "heading",
          id: "heading",
          header: "heading",
          enableSorting: false,
        },
      ],
      data: [
        {
          key: "Values",
          time: "2024-04-29 16:54:29.128436+00",
          tags_id: 55760,
          latitude: 26.73176,
          longitude: 80.13273,
          velocity: 95.0,
          heading: 80.0,
        },
      ],
    },
    queryPerformanceExamples: [
      {
        name: "tableMaxVelocity",
        description:
          "Query to identify the maximum velocity across a truck's fleet on a one month period.",
        graph: [
          { name: "Regular table", progress: 100, value: 4, unit: "s" },
          { name: "Hypertable", progress: 50, value: 2, unit: "s" },
          { name: "Compressed hypertable", progress: 25, value: 1, unit: "s" },
        ],
        code: {
          postgres: `SELECT
  MAX(velocity)
FROM ts_demo.readings
WHERE
  time >= '2024-04-01'
  AND time < '2024-05-01'`,
          ht: `SELECT
  MAX(velocity)
FROM ts_demo.readings_hyper
WHERE
  time >= '2024-04-01'
  AND time < '2024-05-01'`,
          htCompressed: `SELECT
  MAX(velocity)
FROM ts_demo.readings_hyper_compress
WHERE
  time >= '2024-04-01'
  AND time < '2024-05-01'`,
        },
      },
      {
        name: "tableMaxVelocityWeekly",
        description:
          "Query to identify the weekly maximum velocity across a truck's fleet on a one month period.",
        graph: [
          { name: "Regular table", progress: 100, value: 9, unit: "s" },
          { name: "Hypertable", progress: 44.4, value: 4, unit: "s" },
          {
            name: "Compressed hypertable",
            progress: 33.3,
            value: 3,
            unit: "s",
          },
        ],
        code: {
          postgres: `SELECT
  TIME_BUCKET(INTERVAL '1 week', time) as week,
  MAX(velocity)
FROM ts_demo.readings
WHERE
  time >= '2024-04-01'
  AND time < '2024-05-01'
GROUP BY week
ORDER BY week;`,
          ht: `SELECT
  TIME_BUCKET(INTERVAL '1 week', time) as week,
  MAX(velocity)
FROM ts_demo.readings_hyper
WHERE
  time >= '2024-04-01'
  AND time < '2024-05-01'
GROUP BY week
ORDER BY week;`,
          htCompressed: `SELECT
  TIME_BUCKET(INTERVAL '1 week', time) as week,
  MAX(velocity)
FROM ts_demo.readings_hyper_compress
WHERE
  time >= '2024-04-01'
  AND time < '2024-05-01'
GROUP BY week
ORDER BY week;`,
        },
      },
    ],
    costSavingExample: {
      graph: [
        { name: "Readings table", progress: 100, value: 4.53, unit: "GiB" },
        {
          name: "Compressed Readings table",
          progress: 18.0,
          value: 835.7,
          unit: "MiB",
        },
      ],
      compressionFactor: 5,
      compressionPercentage: 82,
    },
  },
  NYCTaxi: {
    name: "NYC Taxi",
    logo: "🚕",
    description: "Find out about taxi rides taken in and around NYC.",
    serviceName: "NYC Taxi sample data",
    tableName: "ts_demo.trips",
    tag: "11.14 GiB",
    disabled: false,
    demoId: 69,
    totalRecords: "42.6 million",
    recordDescription: "NYC trip details",
    databasePeak: {
      definition: [
        {
          accessorKey: "key",
          id: "key",
          header: "Column names",
          size: 200,
          enableSorting: false,
        },
        {
          accessorKey: "started_at",
          id: "started_at",
          header: "started_at",
          enableSorting: false,
        },
        {
          accessorKey: "ended_at",
          id: "ended_at",
          header: "ended_at",
          enableSorting: false,
        },
        {
          accessorKey: "distance",
          id: "distance",
          header: "distance",
          enableSorting: false,
        },
        {
          accessorKey: "tip_amount",
          id: "tip_amount",
          header: "tip_amount",
          enableSorting: false,
        },
        {
          accessorKey: "total_amount",
          id: "total_amount",
          header: "total_amount",
          enableSorting: false,
        },
        {
          accessorKey: "cab_type_id",
          id: "cab_type_id",
          header: "cab_type_id",
          enableSorting: false,
        },
      ],
      data: [
        {
          key: "Values",
          started_at: "2019-09-30 23:59:59+00",
          ended_at: "2019-10-01 00:14:21+00",
          distance: 2.45,
          tip_amount: 0.3,
          total_amount: 2.5,
          cab_type_id: 1,
        },
      ],
    },
    queryPerformanceExamples: [
      {
        name: "tableHighestPaid",
        description:
          "Query to identify the highest amount paid for a trip in a specified month",
        graph: [
          { name: "Regular table", progress: 100, value: 7, unit: "s" },
          { name: "Hypertable", progress: 28.571, value: 2, unit: "s" },
          {
            name: "Compressed hypertable",
            progress: 14.286,
            value: 1,
            unit: "s",
          },
        ],
        code: {
          postgres: `SELECT
  MAX(total_amount) max_total_amount
FROM ts_demo.trips
WHERE started_at >= '2019-09-01' AND started_at < '2019-10-01';`,
          ht: `SELECT
  MAX(total_amount) max_total_amount
FROM ts_demo.trips_hyper
WHERE started_at >= '2019-09-01' AND started_at < '2019-10-01';`,
          htCompressed: `SELECT
  MAX(total_amount) max_total_amount
FROM ts_demo.trips_hyper_compress
WHERE started_at >= '2019-09-01' AND started_at < '2019-10-01';`,
        },
      },
      {
        name: "tableHighestWeekly",
        description:
          "Query to identify the highest amount paid for a trip in a specified month",
        graph: [
          { name: "Regular table", progress: 100, value: 12, unit: "s" },
          { name: "Hypertable", progress: 39.167, value: 7, unit: "s" },
          { name: "Compressed hypertable", progress: 25, value: 3, unit: "s" },
        ],
        code: {
          postgres: `SELECT
  TIME_BUCKET(INTERVAL '1 week', started_at) as week,
  MAX(total_amount) max_total_amount
FROM ts_demo.trips
WHERE
  started_at >= '2019-09-01'
  AND started_at < '2019-10-01'
GROUP BY week
ORDER BY week;`,
          ht: `SELECT
  TIME_BUCKET(INTERVAL '1 week', started_at) as week,
  MAX(total_amount) max_total_amount
FROM ts_demo.trips_hyper
WHERE
  started_at >= '2019-09-01'
  AND started_at < '2019-10-01'
GROUP BY week
ORDER BY week;`,
          htCompressed: `SELECT
  TIME_BUCKET(INTERVAL '1 week', started_at) as week,
  MAX(total_amount) max_total_amount
FROM ts_demo.trips_hyper_compress
WHERE
  started_at >= '2019-09-01'
  AND started_at < '2019-10-01'
GROUP BY week
ORDER BY week;`,
        },
      },
    ],
    costSavingExample: {
      graph: [
        { name: "Trips table", progress: 100, value: 4.6, unit: "GiB" },
        {
          name: "Compressed Trips table",
          progress: 17.109,
          value: 787,
          unit: "MiB",
        },
      ],
      compressionFactor: 6,
      compressionPercentage: 80,
    },
  },
  Crypto: {
    name: "Twelve Data's Crypto Ticks",
    logo: "🪙",
    description:
      "Imports tick-level trading data for various cryptocurrencies.",
    serviceName: "Crypto Ticks sample data",
    tableName: "ts_demo.crypto_ticks",
    tag: "16.37 GiB",
    disabled: true,
    demoId: 70,
    totalRecords: "63.4 million",
    recordDescription: "crypto asset ticks",
    databasePeak: {
      definition: [
        {
          accessorKey: "key",
          id: "key",
          header: "Column names",
          size: 200,
          enableSorting: false,
        },
        {
          accessorKey: "time",
          id: "time",
          header: "time",
          enableSorting: false,
        },
        {
          accessorKey: "symbol",
          id: "symbol",
          header: "symbol",
          enableSorting: false,
        },
        {
          accessorKey: "price",
          id: "price",
          header: "price",
          enableSorting: false,
        },
        {
          accessorKey: "day_volume",
          id: "day_volume",
          header: "day_volume",
          enableSorting: false,
        },
      ],
      data: [
        {
          key: "Values",
          time: "2022-05-25 23:59:59+00",
          symbol: "ADA/USD",
          price: 0.5144,
          day_volume: 5552864,
        },
      ],
    },
    queryPerformanceExamples: [
      {
        name: "minMaxAssetPrice",
        description:
          "Query to fetch the minimum and maximum value of every crypto asset in the last quarter of a year",
        graph: [
          { name: "Regular table", progress: 100, value: 12, unit: "s" },
          { name: "Hypertable", progress: 41.66, value: 5, unit: "s" },
          { name: "Compressed hypertable", progress: 25, value: 3, unit: "s" },
        ],
        code: {
          postgres: `SELECT
  symbol,
  MAX(price) AS high,
  MIN(price) AS low
FROM ts_demo.crypto_ticks
WHERE time >= '2023-10-01' AND time < '2024-01-01'
GROUP BY symbol;`,
          ht: `SELECT
  symbol,
  MAX(price) AS high,
  MIN(price) AS low
FROM ts_demo.crypto_ticks_hyper
WHERE time >= '2023-10-01' AND time < '2024-01-01'
GROUP BY symbol;`,
          htCompressed: `SELECT
  symbol,
  MAX(price) AS high,
  MIN(price) AS low
FROM ts_demo.crypto_ticks_hyper_compress
WHERE time >= '2023-10-01' AND time < '2024-01-01'
GROUP BY symbol;`,
        },
      },
      {
        name: "minMaxAssetPriceWeekly",
        description:
          "Query to generate a daily summary of price and volume for each asset, covering a period of 15 days",
        graph: [
          { name: "Regular table", progress: 100, value: 3, unit: "s" },
          { name: "Hypertable", progress: 34.83, value: 1.5, unit: "s" },
          {
            name: "Compressed hypertable",
            progress: 34.63,
            value: 1.3,
            unit: "s",
          },
        ],
        code: {
          postgres: `SELECT
  time_bucket('1 day', time) AS bucket,
  symbol,
  FIRST(price, time) AS "open",
  MAX(price) AS high,
  MIN(price) AS low,
  LAST(price, time) AS "close",
  LAST(day_volume, time) AS day_volume
FROM ts_demo.crypto_ticks
WHERE
  time >= '2023-10-01'
  AND time < '2023-10-15'
GROUP BY bucket, symbol
ORDER BY bucket, symbol;`,
          ht: `SELECT
  time_bucket('1 day', time) AS bucket,
  symbol,
  FIRST(price, time) AS "open",
  MAX(price) AS high,
  MIN(price) AS low,
  LAST(price, time) AS "close",
  LAST(day_volume, time) AS day_volume
FROM ts_demo.crypto_ticks_hyper
WHERE
  time >= '2023-10-01'
  AND time < '2023-10-15'
GROUP BY bucket, symbol
ORDER BY bucket, symbol;`,
          htCompressed: `SELECT
  time_bucket('1 day', time) AS bucket,
  symbol,
  FIRST(price, time) AS "open",
  MAX(price) AS high,
  MIN(price) AS low,
  LAST(price, time) AS "close",
  LAST(day_volume, time) AS day_volume
FROM ts_demo.crypto_ticks_hyper_compress
WHERE
  time >= '2023-10-01'
  AND time < '2023-10-15'
GROUP BY bucket, symbol
ORDER BY bucket, symbol;`,
        },
      },
    ],
    costSavingExample: {
      graph: [
        { name: "Trips table", progress: 100, value: 7.13, unit: "GiB" },
        {
          name: "Compressed Trips table",
          progress: 13.0,
          value: 730,
          unit: "MiB",
        },
      ],
      compressionFactor: 10,
      compressionPercentage: 87,
    },
  },
};

export default environments;
