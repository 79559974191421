// StatSig feature gates

type GateConfig = {
  GATE: string; // The StatSig id.
  NAME: string; // The name of the experiment.
  DESCRIPTION: string; // Keep it short and concise!
  EXPIRATION: string; // The date the experiment plans on being removed.
  EVENTS?: { [key in EXP_EVENTS]?: string }; // Optional events that can be logged for this experiment.
  META?: { [key in EXP_META]?: string }; // Optional meta events that can be logged for this experiment.
};

// You can fire events from withing the Timescale Console. This is a list of
// comments that are used to log events for the experiments.
export enum EXP_EVENTS {
  CLICK_NEEDS_HELP = "click_needs_help",
  ADD_ONE_HA_REPLICA = "add_one_ha_replica",
  ADD_TWO_HA_REPLICAS = "add_two_ha_replicas",
  SERVICE_CREATED = "service_created",
  SERVICE_DELETED = "service_deleted",
  ADD_HA_REPLICA = "add_ha_replica", // Delete once EXP_RECOMMEND_HA_REPLICA is remove
  ADD_HA_REPLICA_RECOMMENDATION = "add_ha_replica_recommendation",
  ADD_CONNECTION_POOL_RECOMMENDATION = "add_connection_pool_recommendation",
  ADD_HYPERTABLE_RECOMMENDATION = "add_hypertable_recommendation",
  NEW_USER_UNVERIFIED = "new_user_unverified",
  NEW_USER_VERIFIED = "new_user_verified",
  DOWNLOAD_CONFIG = "download_config",
  MARK_CONNECTION_AS_COMPLETE = "mark_connection_as_complete",
  COPY_CONNECTION_STRING = "copy_connection_string",
  CSV_PARSE_ERROR = "csv_parse_error",
  CSV_UPLOAD_ERROR = "csv_upload_error",
  CSV_PROCESSING_ERROR = "csv_processing_error",
  CSV_SUCCESS = "csv_success",
  PARQUET_PARSE_ERROR = "parquet_parse_error",
  PARQUET_UPLOAD_ERROR = "parquet_upload_error",
  PARQUET_PROCESSING_ERROR = "parquet_processing_error",
  PARQUET_SUCCESS = "parquet_success",
}

// Some events need extra metadata to give a more complete picture. A common
// example is adding the service type, or what kind of service was created.
export enum EXP_META {
  ADD_HA_REPLICA = "add_ha_replica",
  DOWNLOAD_CONFIG = "download_config",
}

// Sometimes we just need to fire a generic event not related to an experiment.
export enum SYSTEM_EVENTS {
  PROJECT_CREATED = "project_created",
  PAYMENT_METHOD_ADDED = "payment_method_added",
  CLICK_DATA_MODE = "click_data_mode",
}

// A template for creating new experiments.
//
// export const TEMPLATE: GateConfig = {
//   GATE: "",
//   NAME: "",
//   DESCRIPTION: '',
//   EXPIRATION: "",
//   EVENTS: {},
//   META: {},
// };

export const EXP_PRICING_CALC: GateConfig = {
  GATE: "exp_pricing_calc",
  NAME: "Pricing Calculator",
  DESCRIPTION: 'Shows a Pricing Calculator on the "Create Service" page.',
  EXPIRATION: "2024-05-05",
  EVENTS: {},
};

export const EXP_SIGNUP_COPY: GateConfig & {
  EVENTS: {
    NEW_USER_UNVERIFIED: EXP_EVENTS.NEW_USER_UNVERIFIED;
    NEW_USER_VERIFIED: EXP_EVENTS.NEW_USER_VERIFIED;
  };
} = {
  GATE: "exp_signup_content_v1",
  NAME: "Signup content",
  DESCRIPTION: "Add features list on left side component.",
  EXPIRATION: "2024-06-15",
  EVENTS: {
    NEW_USER_UNVERIFIED: EXP_EVENTS.NEW_USER_UNVERIFIED,
    NEW_USER_VERIFIED: EXP_EVENTS.NEW_USER_VERIFIED,
  },
};

export const EXP_TIERING: GateConfig = {
  GATE: "exp_tiering",
  NAME: "Tier subscription model",
  DESCRIPTION: "Adds tier billing to the app",
  EXPIRATION: "2024-12-15",
  EVENTS: {},
};

export const FEAT_COMPRESSION_WIZARD: GateConfig = {
  GATE: "feat_compression_wizard",
  NAME: "Compression Wizard",
  DESCRIPTION:
    "Add a wizard to help users set compression for their caggs and hypertables.",
  EXPIRATION: "2025-01-01",
};

export const FEAT_MULTIPLE_HA_REPLICAS: GateConfig & {
  EVENTS: {
    SERVICE_CREATED: EXP_EVENTS.SERVICE_CREATED;
    ADD_ONE_HA_REPLICA: EXP_EVENTS.ADD_ONE_HA_REPLICA;
    ADD_TWO_HA_REPLICAS: EXP_EVENTS.ADD_TWO_HA_REPLICAS;
  };
} = {
  GATE: "feat_multiple_ha_replicas",
  NAME: "Multiple HA Replicas",
  DESCRIPTION:
    "Adds the ability to have more than 1 HA replica per service (currently up to 2).",
  EXPIRATION: "2025-01-01",
  EVENTS: {
    SERVICE_CREATED: EXP_EVENTS.SERVICE_CREATED,
    ADD_ONE_HA_REPLICA: EXP_EVENTS.ADD_ONE_HA_REPLICA,
    ADD_TWO_HA_REPLICAS: EXP_EVENTS.ADD_TWO_HA_REPLICAS,
  },
};

export const FEAT_AI_EMBEDDINGS: GateConfig = {
  GATE: "feat_ai_embeddings",
  NAME: "AI embeddings",
  DESCRIPTION:
    "Adds the ability to create and update embeddings automatically.",
  EXPIRATION: "2025-01-01",
  EVENTS: {},
  META: {},
};

export const FEAT_SAMPLE_DATA_QUERY_WIDGET: GateConfig = {
  GATE: "feat_sample_data_query_widget",
  NAME: "Sample Data Query Widget",
  DESCRIPTION: "Use the popsql query widget for sample data example queries.",
  EXPIRATION: "2024-09-30",
  EVENTS: {},
  META: {},
};

export const EXP_ORB: GateConfig = {
  GATE: "exp_orb",
  NAME: "orb products",
  DESCRIPTION: "Orb product integration with plan selection and entitlements.",
  EXPIRATION: "2024-12-15",
  EVENTS: {},
};

export const FEAT_QUAKE_SQL: GateConfig = {
  GATE: "feat_quake_sql",
  NAME: "Quake-style SQL editor",
  DESCRIPTION:
    "Pressing Shift-` will open a Quake-style SQL editor when inside a Service context.",
  EXPIRATION: "2030-01-01",
  EVENTS: {},
};

export const FEAT_SNOW: GateConfig = {
  GATE: "FEAT_SNOW",
  NAME: "Holiday Snow",
  DESCRIPTION:
    'Turns on a snow effect for the holidays. Migrated from the "CHISTMAS" gate.',
  EXPIRATION: "2030-01-01",
  EVENTS: {},
  META: {},
};

export const FEAT_INTERNAL_INSIGHTS: GateConfig = {
  GATE: "internal_insights_interval_options",
  NAME: "Internal Insights Interval Options",
  DESCRIPTION:
    "Adds additional interval options to the Insights page (@robatticus).",
  EXPIRATION: "2030-01-01",
  EVENTS: {},
  META: {},
};

export const FEAT_SKIP_REGION_VALIDATION: GateConfig = {
  GATE: "feat_skip_region_validation",
  NAME: "Skip validation for region selection",
  DESCRIPTION:
    "WARNING: This shows all products in all regions, regardless if regions are present or complete. Missing regions in products will cause the UI to be unstable until the API catches up.",
  EXPIRATION: "2030-01-01",
  EVENTS: {},
  META: {},
};

// Add to the list to show for the ExperimentsModal.
export const ALL_GATES: GateConfig[] = [
  EXP_PRICING_CALC,
  EXP_SIGNUP_COPY,
  EXP_TIERING,
  FEAT_COMPRESSION_WIZARD,
  EXP_ORB,
  FEAT_MULTIPLE_HA_REPLICAS,
  FEAT_QUAKE_SQL,
  FEAT_AI_EMBEDDINGS,
  FEAT_SNOW,
  FEAT_INTERNAL_INSIGHTS,
  FEAT_SKIP_REGION_VALIDATION,
];

/**
 * Target gates need to be "checked" to enroll brand new users.
 * Each experiment that needs to target brand new users specifially
 * need their own targeting gate. If your experiment does not require validating
 * against brand new users, then you can safely ignore target gates.
 *
 * All target gates in this list will be automatically checked when a new user
 * logs in for the first time.
 */

export const TARGET_CSV_IMPORT_V3: GateConfig = {
  GATE: "target_csv_import_v3",
  NAME: "CSV Import V2 - Upload UI (Target gate)",
  DESCRIPTION: "All new users (i.e. projects) need this target gate.",
  EXPIRATION: "2024-08-30",
  EVENTS: {},
  META: {},
};

export const TARGET_SAMPLE_DATA_V2: GateConfig = {
  GATE: "target_sample_data_v2",
  NAME: "Sample Data V2 (Target gate)",
  DESCRIPTION: "Adds the ability to preload a service with sample data.",
  EXPIRATION: "2024-08-30",
  EVENTS: {},
  META: {},
};

export const TARGET_DATA_IMPORT: GateConfig = {
  GATE: "target_data_import",
  NAME: "Data Import Wizard (target gate)",
  DESCRIPTION: 'Add data import wizard "Import Data" page.',
  EXPIRATION: "2024-08-30",
  EVENTS: {},
  META: {},
};

export const TARGET_CUSTOM_ONBOARDING: GateConfig = {
  GATE: "target_custom_onboarding_v1",
  NAME: "Custom Onboarding (target gate)",
  DESCRIPTION:
    "Displays a new flow in replacement of our default Create Service flow for new users",
  EXPIRATION: "2024-11-01",
  EVENTS: {},
  META: {},
};

export const TARGET_LIVE_MIGRATIONS: GateConfig = {
  GATE: "target_live_migrations",
  NAME: "Live migrations (target gate)",
  DESCRIPTION:
    "Displays a new tile for live migrations in data import page and actions hub",
  EXPIRATION: "2024-12-01",
  EVENTS: {},
  META: {},
};

export const NEW_USER_TARGET_GATES: GateConfig[] = [
  TARGET_CSV_IMPORT_V3,
  TARGET_DATA_IMPORT,
  TARGET_SAMPLE_DATA_V2,
  TARGET_CUSTOM_ONBOARDING,
  TARGET_LIVE_MIGRATIONS,
];
