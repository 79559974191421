import { observer } from "mobx-react";
import { useStores } from "stores";
import { typography } from "theme/foundations/typography";
import { Alert, GridItem, Collapse, Flex } from "@chakra-ui/react";
import CloseButton from "../button/CloseButton";
import { ChakraAlertStatus } from "types";

export const NotificationBanner = observer(() => {
  const { notificationStore } = useStores();
  const { alert } = notificationStore;

  let messageDisplayed;
  if (typeof alert.message === "string") {
    messageDisplayed = alert.message;
  } else {
    const { message: Message } = alert;
    messageDisplayed = <Message />;
  }

  const bannerStyle: Record<ChakraAlertStatus, string> = {
    info: "primary.900",
    warning: "warning.900",
    error: "error.900",
    success: "success.900",
  };

  return (
    <GridItem area="notificationBanner" data-cy="NotificationsBanner">
      <Collapse in={alert.show} style={{ zIndex: 1200 }}>
        <Alert
          status={alert.alertType.toLowerCase() as ChakraAlertStatus}
          bg={bannerStyle[alert.alertType.toLowerCase() as ChakraAlertStatus]}
          alignItems="center"
          alignContent="space-between"
          justifyContent="center"
          padding={`8px ${alert.canDismiss ? "8px" : "16px"} 8px ${
            alert.canDismiss ? "40px" : "16px"
          }`}
          gap="16px"
          width="100%"
        >
          <Flex
            width="100%"
            alignItems="center"
            justifyContent="center"
            {...typography.textStyles.base}
            color="grayscale.0"
          >
            {messageDisplayed}
          </Flex>
          {alert.canDismiss && (
            <CloseButton
              size="Large"
              fill="grayscale.0"
              alignSelf="flex-start"
              ml="16px"
              mt="2px"
              onClick={() => {
                notificationStore.setAlert({ ...alert, show: false });
              }}
            />
          )}
        </Alert>
      </Collapse>
    </GridItem>
  );
});
