import { Link, chakra } from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";

export const PaymentMethodDeletion = ({ itemName = "service" }) => (
  <>
    Your payment method is scheduled for deletion. Please cancel the deletion in
    the&nbsp;
    <Link
      as={ReactLink}
      to="/dashboard/billing"
      textStyle="baseBold"
      color="grayscale.0"
    >
      billing section
    </Link>
    &nbsp;or add a new card to create a new {itemName}.
  </>
);

export const PaymentMethodTrialExpiring = () => (
  <>
    Your trial is expiring soon. Please add a&nbsp;
    <chakra.a
      href="/dashboard/billing/details/payment_methods/add_payment"
      textStyle="buttonLinkS"
    >
      payment method
    </chakra.a>
    .
  </>
);

export const PaymentMethodAddition = () => (
  <>
    Please add a&nbsp;
    <chakra.a
      href="/dashboard/billing/details/payment_methods/add_payment"
      textStyle="buttonLinkS"
    >
      payment method
    </chakra.a>
    . If we don&apos;t see an update, we&apos;ll pause any active services.
  </>
);

export const UnstableServices = () => (
  <>
    Restarting your service is taking longer than usual. If the problem persists
    for more than an hour please&nbsp;
    <chakra.a href="/dashboard/support" textStyle="buttonLinkS">
      contact
    </chakra.a>
    &nbsp;support.
  </>
);
