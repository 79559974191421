import {
  Box,
  forwardRef,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
  useBreakpointValue,
  Popover,
  PopoverAnchor,
} from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { usePrevious } from "react-use";
import searchClient from "utils/searchClient";
import { InputSearch } from "components/search/Search";
import Collapse from "components/collapse/Collapse";
import { getPathnameSegments } from "utils/utilFunctions";
import { VerticalNavMenuItem } from "components/navigation/internal/VerticalNavMenuItem";
import { Icons } from "components/icon/iconTypes";
import { ModalFeedback } from "../ModalFeedback";
import Divider from "components/divider/Divider";
import { useStores } from "stores";
import { observer } from "mobx-react";
import {
  MenuGroup,
  MenuItemLinkProps,
  MenuItem,
  SmallScreenMenu,
  MenuItemLink,
} from "../menuComponents";
import {
  GuidedTourHelpAndSupport,
  GuidedTourPopoverContent,
} from "../guidedTour/LeftNavGuidedTour";
import { GuidedTourContext } from "../guidedTour/GuidedTourProvider";
import { LeftNavContext } from "../NavigationProvider";

const HelpCenterMenuButton = forwardRef((props, ref) => (
  <Flex ref={ref}>
    <VerticalNavMenuItem
      isChevron={true}
      item={{
        id: "support",
        label: "Help & Support",
        icon: "status/Question",
      }}
      truncate={false}
      {...props}
    />
  </Flex>
));

type SearchResult = {
  pageTitle: string;
  href: string;
};

function isSearchResult(result: unknown) {
  return (
    typeof (result as SearchResult).pageTitle === "string" &&
    typeof (result as SearchResult).href === "string"
  );
}

const fetchSearchResults = async (
  searchTerm: string,
  setter: (results: SearchResult[]) => void,
) => {
  try {
    const results = await searchClient.search(searchTerm, {
      hitsPerPage: 3,
    });
    if ("hits" in results && Array.isArray(results.hits)) {
      setter(
        (results.hits as any[]).filter((result) => isSearchResult(result)),
      );
    }
  } catch (e) {
    console.error("Algolia search error: ", e);
  }
};

export const HelpCenter = () => {
  const { pathname } = useLocation();
  const params = useParams();
  const previousPathname = usePrevious(pathname);
  const [initialResults, setInitialResults] = useState<SearchResult[]>([]);
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const [showSmallScreenMenu, setShowSmallScreenMenu] = useState(false);

  if (pathname !== previousPathname && initialResults.length) {
    setInitialResults([]);
  }

  const {
    isOpen: isRequestFeatureOpen,
    onOpen: onRequestFeatureOpen,
    onClose: onRequestFeatureClose,
  } = useDisclosure();

  const defaultSearchTerm = getPathnameSegments(pathname, {
    filterOut: [...Object.values(params), "dashboard"],
  }).join(" ");

  const prefetchSearchResults = async () => {
    if (initialResults.length) {
      return;
    }
    fetchSearchResults(defaultSearchTerm, setInitialResults);
  };

  const {
    onOpen: onPopoverOpen,
    onClose: onPopoverClose,
    isOpen: isPopoverOpen,
  } = useDisclosure();

  const { currentTourStep, goStepBack } = useContext(GuidedTourContext);

  useEffect(
    () => (currentTourStep === 3 ? onPopoverOpen() : onPopoverClose()),
    [currentTourStep, onPopoverOpen, onPopoverClose],
  );

  return (
    <>
      <ModalFeedback
        isOpen={isRequestFeatureOpen}
        onClose={() => onRequestFeatureClose()}
      />
      {isSmallScreen ? (
        <>
          <HelpCenterMenuButton
            onMouseEnter={prefetchSearchResults}
            onClick={() => setShowSmallScreenMenu(!showSmallScreenMenu)}
          />
          {showSmallScreenMenu && (
            <SmallScreenMenu
              title="Help & Support"
              onTitleClick={() => setShowSmallScreenMenu(false)}
            >
              <HelpCenterMenuList
                initialResults={initialResults}
                onRequestFeatureOpen={onRequestFeatureOpen}
              />
            </SmallScreenMenu>
          )}
        </>
      ) : (
        <Menu
          placement={isSmallScreen ? "top" : "right"}
          gutter={16}
          autoSelect={false}
          data-heap="help-center-menu"
        >
          {({ isOpen }) => (
            <>
              <Popover
                isOpen={isPopoverOpen}
                onOpen={onPopoverOpen}
                placement="right"
                gutter={20}
                arrowSize={12}
              >
                <PopoverAnchor>
                  <MenuButton
                    as={HelpCenterMenuButton}
                    isActive={isOpen}
                    data-cy="help-menu-item"
                    onMouseEnter={prefetchSearchResults}
                  />
                </PopoverAnchor>
                <GuidedTourPopoverContent
                  confirmText="Done!"
                  onCancel={goStepBack}
                >
                  <GuidedTourHelpAndSupport />
                </GuidedTourPopoverContent>
              </Popover>
              <MenuList w={isSmallScreen ? "100vw" : "360px"} py="8px">
                <HelpCenterMenuList
                  initialResults={initialResults}
                  onRequestFeatureOpen={onRequestFeatureOpen}
                />
              </MenuList>
            </>
          )}
        </Menu>
      )}
    </>
  );
};

const HelpCenterMenuList = observer(
  ({
    initialResults = [],
    onRequestFeatureOpen,
  }: {
    initialResults?: SearchResult[];
    onRequestFeatureOpen: () => void;
  }) => {
    const { projectsStore } = useStores();
    const { isFreeTrial } = projectsStore;
    const { setIsMenuOpen } = useContext(LeftNavContext);
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [searchTerm, setSearchTerm] = useState("");

    async function onInputValueChange(value: string | undefined) {
      setSearchTerm(value ?? "");

      if (value) {
        fetchSearchResults(value, setSearchResults);
      } else {
        setSearchResults([]);
      }
    }

    const relatedResources = initialResults.map((result) => {
      return {
        id: result.href,
        to: result.href,
        label: result.pageTitle,
        iconName: "actions/Arrow/Simple/Right" as Icons,
        isExternal: true,
      };
    });

    const searchedResources = searchResults.map((result) => {
      return {
        id: result.href,
        to: result.href,
        label: result.pageTitle,
        iconName: "actions/Arrow/Simple/Right" as Icons,
        isExternal: true,
      };
    });

    const docsStaticItems = [
      {
        id: "getStarted",
        label: "Get started",
        to: "https://docs.timescale.com/getting-started/latest/",
        iconName: "elements/Thunder/On" as Icons,
        isExternal: true,
      },
      {
        id: "tutorials",
        label: "Tutorials",
        to: "https://docs.timescale.com/tutorials/latest/",
        iconName: "elements/Book/Open" as Icons,
        isExternal: true,
      },
    ];

    const supportItems = [
      {
        id: "contactSupport",
        label: "Talk with support",
        to: "/dashboard/support",
        iconName: "elements/Support" as Icons,
        onClick: () => setIsMenuOpen(false),
      },
      {
        id: "feedback",
        label: "Send feedback to the product team",
        iconName: "elements/Star" as Icons,
        onClick: () => onRequestFeatureOpen(),
      },
      {
        id: "JoinSlack",
        label: "Join our community Slack",
        iconName: "elements/World" as Icons,
        to: "https://timescaledb.slack.com/?ref=timescale.com",
        isExternal: true,
      },
    ];

    const systemStatus = {
      id: "systemStatus",
      label: "Check the system status",
      to: "https://status.timescale.com/",
      iconName: "status/Check/Circle-Big" as Icons,
      isExternal: true,
    };

    const changelog = {
      id: "changelog",
      label: "Read our changelog",
      iconName: "status/Megaphone" as Icons,
      to: "https://docs.timescale.com/about/latest/changelog/",
      isExternal: true,
    };

    return (
      <>
        {isFreeTrial && (
          <>
            <LearnMore items={docsStaticItems} isFreeTrial={isFreeTrial} />
            <ContactUs items={supportItems} />
          </>
        )}

        <MenuGroup title="Related documentation">
          <>
            <Box px="8px" py="4px">
              <InputSearch
                name="Search"
                onChange={onInputValueChange}
                value={searchTerm}
                debounceTimeMs={100}
              />
            </Box>
            <Collapse in={!!searchedResources.length}>
              {searchedResources.map((item, i) => (
                <MenuItemLink key={i} {...item} p="8px" />
              ))}
            </Collapse>

            {relatedResources.map((item, i) => (
              <MenuItem key={i} {...item} />
            ))}
            <MenuItem {...changelog} />
          </>
        </MenuGroup>

        {!isFreeTrial && (
          <>
            <ContactUs items={supportItems} />
            <LearnMore items={docsStaticItems} isFreeTrial={isFreeTrial} />
          </>
        )}
        <Divider my="8px" />
        <MenuItem {...systemStatus} />
      </>
    );
  },
);

const ContactUs = ({ items }: { items: MenuItemLinkProps[] }) => (
  <>
    <Divider mt="8px" />
    <Box bg="softGradient.90" py="8px">
      <MenuGroup title="Contact us">
        {items.map((item, i) => (
          <MenuItem key={i} {...item} />
        ))}
      </MenuGroup>
    </Box>
    <Divider mb="8px" />
  </>
);

const LearnMore = ({
  items,
  isFreeTrial,
}: {
  items: MenuItemLinkProps[];
  isFreeTrial: boolean;
}) => (
  <MenuGroup title={isFreeTrial ? "Learn about Timescale!" : "Learn more"}>
    {items.map((item, i) => (
      <MenuItem key={i} {...item} />
    ))}
  </MenuGroup>
);
