import { autorun, makeAutoObservable, runInAction } from "mobx";
import { client } from "api/client";
import userStore from "./userStore";

import { SEND_SUPPORT_EMAIL } from "api/mutations";
import { LOADING, NOT_INITIATED, READY } from "./constants";
import { SupportEmailDestinations } from "types";

export type SupportEmailProps = {
  serviceIds: string;
  projectId: string;
  subject: string;
  supportQuestion: string;
  email: string;
  destinations?: SupportEmailDestinations[];
  showSnackBar?: boolean;
};

export class EmailStore {
  storeStatus = NOT_INITIATED;

  constructor() {
    makeAutoObservable(this);

    autorun(async () => {
      try {
        if (userStore?.storeStatus === READY && userStore?.currentId) {
          runInAction(() => {
            this.storeStatus = READY;
          });
        } else {
          this._setStoreStatus(NOT_INITIATED);
        }
      } catch {
        console.warn("Error updating paymentMethodsStore");
      }
    });
  }

  _setStoreStatus = (status: string) => {
    runInAction(() => {
      this.storeStatus = status;
    });
  };

  sendSupportEmail = async ({
    serviceIds,
    projectId,
    subject,
    supportQuestion,
    email,
    destinations,
  }: SupportEmailProps) => {
    this._setStoreStatus(LOADING);
    const { currentId, currentUser } = userStore;
    try {
      const { data, errors } = await client.mutate({
        mutation: SEND_SUPPORT_EMAIL,
        variables: {
          serviceId: serviceIds,
          projectId,
          subject,
          formBody: supportQuestion,
          email,
          userId: currentId,
          name: currentUser,
          destinations,
        },
      });
      return { data, errors };
    } catch (e) {
      console.error("System error sendSupportEmail: ", e);
      return { errors: e };
    } finally {
      this._setStoreStatus(READY);
    }
  };
}

const emailStore = new EmailStore();

export default emailStore;
