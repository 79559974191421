import { Box, Popover, PopoverAnchor, useDisclosure } from "@chakra-ui/react";
import {
  ModeOptionProps,
  ModeSwitcher
} from "components/modeSwitcher/ModeSwitcher";
import { PopSQLFrameContext } from "layouts/PopSQLFrame";
import { useCallback, useContext, useEffect } from "react";
import { Statsig } from "statsig-react";
import { SYSTEM_EVENTS } from "utils/featuresGates";
import { GuidedTourContext } from "./guidedTour/GuidedTourProvider";
import {
  GuidedTourNewModes,
  GuidedTourPopoverContent
} from "./guidedTour/LeftNavGuidedTour";
import { LeftNavContext } from "./NavigationProvider";

export const PopSQLSwitch = () => {
  const { show } = useContext(PopSQLFrameContext);
  const { isLeftNavMinified } = useContext(LeftNavContext);

  const handleButtonClick = useCallback(() => {
    show("Mode Switcher");
  }, [show]);

  const modeOptions: [ModeOptionProps, ModeOptionProps] = [
    {
      id: "devOps",
      label: "Ops",
      isChecked: true,
      isTimescale: true,
    },
    {
      id: "queryData",
      label: "Data",
      isChecked: false,
      isTimescale: false,
    },
  ];

  const {
    onOpen: onPopoverOpen,
    onClose: onPopoverClose,
    isOpen: isPopoverOpen,
  } = useDisclosure();

  const { currentTourStep, goStepBack } = useContext(GuidedTourContext);

  useEffect(
    () => (currentTourStep === 1 ? onPopoverOpen() : onPopoverClose()),
    [currentTourStep, onPopoverOpen, onPopoverClose],
  );

  return (
    <Popover
      isOpen={isPopoverOpen}
      onOpen={onPopoverOpen}
      placement="right"
      gutter={20}
      arrowSize={12}
    >
      <PopoverAnchor>
        <Box>
          <ModeSwitcher
            options={modeOptions}
            onClick={() => {
              Statsig.logEvent(SYSTEM_EVENTS.CLICK_DATA_MODE);
              handleButtonClick();
            }}
            isMinified={isLeftNavMinified}
          />
        </Box>
      </PopoverAnchor>
      <GuidedTourPopoverContent onCancel={goStepBack}>
        <GuidedTourNewModes />
      </GuidedTourPopoverContent>
    </Popover>
  );
};