import { TAG_COLOR, TAG_FONT_STYLE } from "./tagTypes";
import { typography } from "theme/foundations/typography";

export const COLOR_CONFIG = (isFilled: boolean) => {
  return {
    [TAG_COLOR.SUCCESS]: {
      backgroundColor: isFilled ? "success.900" : "success.50",
      color: isFilled ? "success.50" : "success.900",
      borderColor: "success.900",
    },
    [TAG_COLOR.WARNING]: {
      backgroundColor: isFilled ? "warning.900" : "warning.50",
      color: isFilled ? "warning.50" : "warning.900",
      borderColor: "warning.900",
    },
    [TAG_COLOR.GRAYSCALE]: {
      backgroundColor: isFilled ? "grayscale.400" : "grayscale.100",
      color: isFilled ? "grayscale.100" : "grayscale.400",
      borderColor: "grayscale.400",
    },
    [TAG_COLOR.GRAYSCALE700]: {
      backgroundColor: isFilled ? "grayscale.700" : "grayscale.100",
      color: isFilled ? "grayscale.100" : "grayscale.700",
      borderColor: "grayscale.700",
    },
    [TAG_COLOR.ERROR]: {
      backgroundColor: isFilled ? "error.900" : "error.50",
      color: isFilled ? "error.50" : "error.900",
      borderColor: "error.900",
    },
    [TAG_COLOR.PRIMARY]: {
      backgroundColor: isFilled ? "primary.900" : "primary.100",
      color: isFilled ? "primary.100" : "primary.900",
      borderColor: "primary.900",
    },
    [TAG_COLOR.PRIMARY600]: {
      backgroundColor: isFilled ? "primary.600" : "primary.100",
      color: isFilled ? "primary.50" : "primary.600",
      borderColor: "primary.600",
    },
  };
};

export const STYLE_CONFIG = (color: string) => {
  return {
    [TAG_FONT_STYLE.UNDERLINE]: {
      cursor: "pointer",
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: color,
      paddingBottom: "1px",
    },
    [TAG_FONT_STYLE.DASHED]: {
      borderBottomWidth: "1px",
      borderBottomStyle: "dashed",
      borderBottomColor: color,
      paddingBottom: "1px",
    },
  };
};

// Controls just the size of the tag.
export const SIZE_CONFIG = {
  small: {
    padding: "3px 8px",
  },
  large: {
    fontSize: typography.fontSizes.md,
    padding: "4px 8px",
  },
};
