import { useState, useEffect } from "react";
import { startCase } from "lodash";
import { Box, Flex, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useStores } from "stores";
import flagsmith from "flagsmith";
import {
  SHOW_FLAG_CHOOSER_ON_PROD,
  IGNORE_FLAGSMITH,
} from "utils/featureFlags";
import Button from "components/button/Button";
import { Modal, ModalButtons } from "components/modal/Modal";
import { Toggle } from "components/selectors/Toggle";
import FeedbackRecommendation from "components/feedbackRecommendation/FeedbackRecommendation";
import { ENV } from "utils/hostEnv";
import { PROD } from "utils/config";
import { FeatureFlagList } from "stores/featuresStore";
import Icon from "components/icon/Icon";
import { TAG_COLOR } from "components/tags/tagTypes";
import TagStatus from "components/tags/TagStatus/TagStatus";

const parseLabel = (flag: string) =>
  startCase(flag).replace(/^(ff|pp|exp)/i, "");
const isExperiment = (flag: string) => /exp_/i.test(flag);

export const FeatureFlagModal = observer(() => {
  const { featuresStore } = useStores();
  const { flagsmithFeatures, getAllFeatureStates } = featuresStore;
  const showFeatures =
    featuresStore.isActiveFeature(SHOW_FLAG_CHOOSER_ON_PROD) ||
    ENV.NAME !== PROD;
  const [allFeatures, setAllFeatures] = useState<FeatureFlagList | null>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setAllFeatures(getAllFeatureStates());
  }, [flagsmithFeatures, getAllFeatureStates]);

  const handleActiveFeatures = () => {
    featuresStore.activateFeatures(allFeatures);
    setShowModal(false);
    window.location.reload();
  };

  const handleCancelModal = () => {
    setShowModal(false);
    setAllFeatures(getAllFeatureStates());
  };

  return (
    showFeatures && (
      <>
        <Button
          onClick={() => setShowModal(true)}
          size="sm"
          variant="ghost"
          position="fixed"
          bottom="3px"
          right="10px"
          zIndex="1400"
          background="grayscale.0"
          data-cy="feature-flag-button"
        >
          🏁 Flags
        </Button>

        <Modal
          title="🏁 Feature flags"
          isOpen={showModal}
          onClose={() => handleCancelModal()}
          width="800px"
        >
          <>
            <Flex
              gap="16px"
              background="warning.100"
              borderRadius="4px"
              p="8px"
              mb="16px"
              mt="-8px"
            >
              <Text as="b">Key:</Text>
              <Flex gap="4px" alignItems="center">
                <Icon name="elements/Flag/Filled" /> Default ON
              </Flex>
              <Flex gap="4px" alignItems="center">
                <Icon name="elements/Experiment" /> Experiment
              </Flex>
            </Flex>
            <Flex
              data-cy="flag-list"
              direction="column"
              maxH="65vh"
              overflowY="auto"
              gap="5px"
              pb="60px"
            >
              {allFeatures &&
                Object.keys(allFeatures).map((feature, i) => (
                  <Flex
                    key={feature}
                    bg={i % 2 ? "primary.300" : "grayscale.0"}
                    flexDirection="row"
                    alignItems="center"
                    width="100%"
                    padding="5px"
                  >
                    <Toggle
                      id={feature}
                      data-cy={feature}
                      isChecked={Boolean(allFeatures[feature])}
                      whiteSpace={"nowrap"}
                      textAlign="left"
                      onChange={() => {
                        // Automatically toggle on `IGNORE_FLAGSMITH` for user.
                        setAllFeatures({
                          ...allFeatures,
                          [IGNORE_FLAGSMITH]: true,
                          [feature]: !allFeatures[feature],
                        });
                      }}
                    >
                      <Flex>
                        <Box mr="4px">
                          {flagsmith.hasFeature(feature) ? (
                            <Icon
                              name="elements/Flag/Filled"
                              fill="grayscale.800"
                            />
                          ) : (
                            <Icon name="elements/Flag" fill="grayscale.300" />
                          )}
                        </Box>
                        <Flex alignItems="center">
                          {parseLabel(feature) + ` [${feature}]`}
                          {isExperiment(feature) && (
                            <TagStatus
                              label="Exp"
                              color={TAG_COLOR.PRIMARY}
                              iconName="elements/Experiment"
                              minHeight="inherit"
                              ml="4px"
                            />
                          )}
                        </Flex>
                      </Flex>
                    </Toggle>
                  </Flex>
                ))}
            </Flex>
            <FeedbackRecommendation
              styleVariant="info"
              isBackgroundOn={true}
              show={true}
              iconName="status/Megaphone"
              mt="10px"
            >
              Flagsmith will override settings unless ignored. Log out to reset.
            </FeedbackRecommendation>
          </>
          <ModalButtons
            primaryCTA={{
              text: "Save",
              onClick: () => handleActiveFeatures(),
              dataCy: "ff-modal-button",
            }}
            secondaryCTA={{
              text: "Cancel",
              onClick: () => handleCancelModal(),
            }}
          />
        </Modal>
      </>
    )
  );
});
