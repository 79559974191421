import ReactDOM from "react-dom";
import { configure } from "mobx";
import { Provider } from "mobx-react";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { PROD, USERPILOT_ACCOUNT_ID } from "utils/config";
import { ENV } from "utils/hostEnv";
import Logger from "utils/logger";
import Cookies from "js-cookie";
import flagsmith from "flagsmith";
import { Routes } from "./routes";
import { client } from "./api/client";
import { stores } from "./stores";
import { Userpilot } from "userpilot";
import { ChakraProvider } from "@chakra-ui/react";
import { timescaleTheme } from "./theme/index";
import { mapFlags } from "stores/storeUtils";
import { printSha } from "utils/utilFunctions";
import { StatsigProvider } from "layouts/StatsigProvider";
import {
  ContextMenuContext,
  ContextMenuProvider,
  TimescaleResultsCacheContextProvider,
} from "@timescale/popsql-query-widget";

import "@timescale/popsql-query-widget/index.css";
import { CurrentServiceQueryEngineContextProvider } from "contexts/queryEngine";

const { featuresStore } = stores;
const search = window?.location?.search;
const urlParams = new URLSearchParams(search);
const trackAnonymousUserPilot = urlParams?.get("up");

Userpilot.initialize(USERPILOT_ACCOUNT_ID);
if (trackAnonymousUserPilot != null) {
  Userpilot.anonymous();
}

if (/verify\/?$/.test(window?.location.pathname)) {
  Cookies.remove("_TS"); //Prevent race condition with autologin from residual cookie
}

//initialize Logging
Logger.init({
  dsn: ENV.SENTRY,
  environment: ENV.NAME,
  release: process.env.REACT_APP_SHA || "NO_RELEASE_FOUND",
});

flagsmith.init({
  environmentID:
    ENV.NAME === PROD ? "8ST723CjvoRUEwMmT9hwNJ" : "McbAGeQwa8kUSiTyMDnuev",
  api: "https://console.cloud.timescale.com/flagsmith/",
  enableAnalytics: false,
  cacheFlags: false,
  onChange: async () => {
    //make sure that updates propagate to the flag chooser modal
    const { email } = flagsmith.getAllTraits();
    if (email && ENV.NAME === PROD) {
      window?.heap.addUserProperties({ email });
    }
    const allFlags = await flagsmith.getAllFlags();

    const heapFlagMap = mapFlags(allFlags, true); // Heap requires booleans to be strings when setting events
    const featureFlagMap = mapFlags(allFlags);
    featuresStore.setFlagsmithFeatures(featureFlagMap);
    // This is a custom Heap event that we can use to filter on any flag state
    if (ENV.NAME === PROD) {
      window?.heap.track(
        email
          ? "Set Flagsmith flags: Identified"
          : "Set Flagsmith flags: Anonymous",
        heapFlagMap,
      );
    }
  },
});

printSha(); // Print the SHA for GitHub Action builds

//debugging
window._____APP_STATE_____ = stores;
configure({ enforceActions: "always", reactionRequiresObservable: true });

const App = (
  <ApolloHooksProvider client={client}>
    <Provider {...stores}>
      <ChakraProvider theme={timescaleTheme}>
        <StatsigProvider>
          <CurrentServiceQueryEngineContextProvider>
            <TimescaleResultsCacheContextProvider
              baseUrl={
                process.env.REACT_APP_API_HOSTNAME || window.location.origin
              }
            >
              <ContextMenuProvider>
                <Routes />
                <ContextMenuContext.Consumer>
                  {({ render }: { render: any }) => render()}
                </ContextMenuContext.Consumer>
              </ContextMenuProvider>
            </TimescaleResultsCacheContextProvider>
          </CurrentServiceQueryEngineContextProvider>
        </StatsigProvider>
      </ChakraProvider>
    </Provider>
  </ApolloHooksProvider>
);

// TODO: Migrate to React 18's new `createRoot` API from `react-dom/client`.
// The new API promises to improve performance as it can better batch render
// updates, however something about this breaks how the app utilizes mouse
// handlers and how that works within cypress. For example, doing `.click()` on
// an `IconButton` in a cypress test does not trigger the `onMouseUp` handler.
// Note, when the TODO is solved, should remove the `legacyRootApi` option
// from storybook `main.js` configuration.
ReactDOM.render(App, document.getElementById("root"));
