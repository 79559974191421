import "./fonts.css";

export const typography = {
  fonts: {
    heading: `"Inter", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Droid Sans", "Helvetica Neue", sans-serif`,
    body: `"Inter", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Droid Sans", "Helvetica Neue", sans-serif`,
    mono: `"Roboto Mono", source-code-pro, "Menlo", "Monaco", "Consolas", "Courier New"`,
  },
  fontSizes: {
    xs: "10px",
    sm: "12px",
    md: "14px",
    lg: "16px",
    xl: "18px",
    "2xl": "20px",
    "3xl": "24px",
    "4xl": "28px",
    "5xl": "32px",
    "6xl": "40px",
  },
  textStyles: {
    h1: {
      fontSize: ["5xl", "5xl", "6xl"],
      fontWeight: "bold",
      lineHeight: ["1.25", "1.25", "1.2"],
      letterSpacing: "-0.01em",
      color: "grayscale.900",
    },
    h2: {
      fontSize: ["5xl"],
      fontWeight: "bold",
      lineHeight: "1.25",
      letterSpacing: "-0.01em",
      color: "grayscale.900",
    },
    h3: {
      fontSize: ["3xl"],
      fontWeight: "bold",
      lineHeight: "1.17",
      letterSpacing: "-0.01em",
      color: "grayscale.900",
    },
    h4: {
      fontSize: ["2xl"],
      fontWeight: "bold",
      lineHeight: "1.2",
      letterSpacing: "-0.01em",
      color: "grayscale.900",
    },
    subtitle1: {
      fontSize: ["3xl"],
      fontWeight: "medium",
      lineHeight: "1.33",
      letterSpacing: "-0.01em",
    },
    subtitle2: {
      fontSize: ["2xl"],
      fontWeight: "medium",
      lineHeight: "1.4",
      letterSpacing: "-0.01em",
    },
    subtitle3: {
      fontSize: ["xl"],
      fontWeight: "medium",
      lineHeight: "1.2",
      letterSpacing: "-0.01em",
      color: "grayscale.700",
    },
    subtitle3Bold: {
      fontSize: ["xl"],
      fontWeight: "bold",
      lineHeight: "1.2",
      letterSpacing: "-0.01em",
    },
    subtitle4: {
      fontSize: ["lg"],
      fontWeight: "medium",
      lineHeight: "1.5",
      letterSpacing: "-0.01em",
    },
    subtitle4Bold: {
      fontSize: ["lg"],
      fontWeight: "bold",
      lineHeight: "1.5",
      letterSpacing: "-0.01em",
    },
    base: {
      fontSize: ["md"],
      fontWeight: "medium",
      lineHeight: "1.43",
      letterSpacing: "-0.01em",
      color: "grayscale.800",
    },
    baseBold: {
      fontSize: ["md"],
      fontWeight: "bold",
      lineHeight: "1.43",
      letterSpacing: "-0.01em",
    },
    codeL: {
      fontFamily: "Roboto Mono",
      fontSize: ["lg"],
      fontWeight: "thin",
      lineHeight: "1.5",
      letterSpacing: "-0.01em",
      fontVariantLigatures: "none",
    },
    codeS: {
      fontFamily: "Roboto Mono",
      fontSize: ["md"],
      fontWeight: "thin",
      lineHeight: "1.43",
      letterSpacing: "-0.01em",
      fontVariantLigatures: "none",
    },
    codeSHighlight: {
      fontFamily: "Roboto Mono",
      fontSize: ["md"],
      lineHeight: "1.43",
      letterSpacing: "-0.01em",
      color: "grayscale.900",
      background: "primary.200",
      padding: "0px 4px",
      borderRadius: "3px",
      fontVariantLigatures: "none",
    },
    buttonL: {
      fontSize: ["lg"],
      fontWeight: "bold",
      lineHeight: "1.5",
      letterSpacing: "-0.01em",
    },
    buttonS: {
      fontSize: ["md"],
      fontWeight: "bold",
      lineHeight: "1.43",
      letterSpacing: "-0.01em",
    },
    buttonLinkL: {
      fontSize: ["lg"],
      fontWeight: "medium",
      lineHeight: "1.5",
      letterSpacing: "-0.01em",
      textDecorationLine: "underline",
    },
    buttonLinkS: {
      fontSize: ["md"],
      fontWeight: "medium",
      lineHeight: "1.43",
      letterSpacing: "-0.01em",
      textDecorationLine: "underline",
    },
    caption: {
      fontSize: ["sm"],
      fontWeight: "medium",
      lineHeight: "1.5",
      letterSpacing: "-0.01em",
    },
    captionLink: {
      fontSize: ["sm"],
      fontWeight: "medium",
      lineHeight: "1.5",
      letterSpacing: "-0.01em",
      textDecorationLine: "underline",
    },
    captionBold: {
      fontSize: ["sm"],
      fontWeight: "bold",
      lineHeight: "1.5",
      letterSpacing: "-0.01em",
    },
    captionCode: {
      fontFamily: "Roboto Mono",
      fontSize: ["xs"],
      fontWeight: "normal",
      lineHeight: "1.6",
      letterSpacing: "-0.01em",
      fontVariantLigatures: "none",
    },
    label: {
      fontSize: ["xs"],
      fontWeight: "medium",
      lineHeight: "1.6",
      letterSpacing: "-0.01em",
    },
    dashed: {
      borderBottom: "1px dashed",
      borderBottomColor: "grayscale.700",
    },
  },
};
