import { reaction, makeAutoObservable, runInAction } from "mobx";
import { CONNECTION, ALERTS } from "utils/config";
import { VISIBILITY_STATE } from "./constants";
import notificationStore from "./notificationStore";

export enum APP_STATE {
  INIT = "init",
  LOADING = "loading",
  READY = "ready",
  ERROR = "error",
}

export class CommonStore {
  connectionStatus = CONNECTION.ONLINE;
  /**
   * The visibility of the current tab.
   * Based on visibilityChange event.
   * See `setVisibilityState()`.
   */
  visibilityState = VISIBILITY_STATE.VISIBLE;
  appState = APP_STATE.INIT;
  appDisabled = false;
  isPageIdle = false;
  isModalOpen = false;
  isDataAvailable = true;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.isDataAvailable,
      (data) =>
        data && notificationStore.alert.type === ALERTS.OFFLINE
          ? notificationStore.setAlert({ show: false })
          : notificationStore.setAlert({
              show: true,
              type: ALERTS.OFFLINE,
              canDismiss: false,
              message:
                "Displaying service information cached in your browser due to problems accessing live data. Some information might not be fresh and some capabilities unavailable.",
            }),
    );
  }

  setConnectionStatus(status: keyof typeof CONNECTION) {
    this.connectionStatus = status;

    if (this.connectionStatus === CONNECTION.OFFLINE) {
      notificationStore.setAlert({
        show: true,
        type: ALERTS.OFFLINE,
        canDismiss: false,
        message:
          "Connection is currently offline and cached data will be available",
      });
    } else if (notificationStore.alert.type === ALERTS.OFFLINE) {
      notificationStore.setAlert({ show: false });
    }
  }

  /**
   * Plug this into an event listener to listen for visibility change events.
   *
   * "This event fires with a visibilityState of hidden when
   * a user navigates to a new page, switches tabs, closes the tab,
   * minimizes or closes the browser, or, on mobile, switches
   * from the browser to a different app."
   *
   * https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
   */
  setVisibilityState = (state: VISIBILITY_STATE) => {
    runInAction(() => {
      this.visibilityState = state;
    });
  };

  setIsDataAvailable = (status: boolean) => {
    runInAction(() => {
      this.isDataAvailable = status;
    });
  };

  setIsPageIdle = (status: boolean) => {
    runInAction(() => {
      this.isPageIdle = status;
    });
  };

  setIsModalOpen = (status: boolean) => {
    runInAction(() => {
      this.isModalOpen = status;
    });
  };

  setAppState = (state: APP_STATE) => {
    runInAction(() => {
      this.appState = state;
    });
  };

  setAppDisabled = (state: boolean) => {
    runInAction(() => {
      this.appDisabled = state;
    });
  };
}

const commonStore = new CommonStore();

export default commonStore;
